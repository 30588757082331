import Cookies from 'js-cookie'

const state = {
    sidebar: {
        opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
        withoutAnimation: false
    },
    device: 'desktop',
    size: Cookies.get('size') || 'medium',

    isShowNotification: false,
    isShowLoading: 0, // 1 显示 0 不显示
    LoadingNum: 0
}

const mutations = {
    TOGGLE_SIDEBAR: state => {
        state.sidebar.opened = !state.sidebar.opened
        state.sidebar.withoutAnimation = false
        if (state.sidebar.opened) {
            Cookies.set('sidebarStatus', 1)
        } else {
            Cookies.set('sidebarStatus', 0)
        }
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
        Cookies.set('sidebarStatus', 0)
        state.sidebar.opened = false
        state.sidebar.withoutAnimation = withoutAnimation
    },
    TOGGLE_DEVICE: (state, device) => {
        state.device = device
    },
    SET_SIZE: (state, size) => {
        state.size = size
        Cookies.set('size', size)
    },
    TOGGLE_NOTIFICATION: state => {
        state.isShowNotification = !state.isShowNotification
    },
    SET_ISSHOWLOADING: (state, show) => {
        state.isShowLoading = show
    },
    SET_LOADINGNUM: (state, num) => {
        state.LoadingNum = num
    }
}

const actions = {
    toggleSideBar({ commit }) {
        commit('TOGGLE_SIDEBAR')
    },
    toggleNotification({ commit }) {
        commit('TOGGLE_NOTIFICATION')
    },
    closeSideBar({ commit }, { withoutAnimation }) {
        commit('CLOSE_SIDEBAR', withoutAnimation)
    },
    toggleDevice({ commit }, device) {
        commit('TOGGLE_DEVICE', device)
    },
    setSize({ commit }, size) {
        commit('SET_SIZE', size)
    },
    setIsShowLoading({ commit }, show) {
        commit('SET_ISSHOWLOADING', show)
    },
    setLoadingNum({ commit }, num) {
        commit('SET_LOADINGNUM', num)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
