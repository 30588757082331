import request from '@/common/http/request'

// 根据名称搜索
export function getGamePageByName(data) {
    return request({
        url: '/gameInfo/pageByName',
        method: 'post',
        data
    })
}
// 获取历史记录
export function getHistory(data) {
    return request({
        url: '/gameInfo/getRedisHistorySearch',
        method: 'post',
        data
    })
}
