<template>
    <div class="content">
        <div class="header">
            <div>{{ $t("Notification.Notification") }}</div>
            <div class="close">
                <i class="iconfont icon-s-cuowu-guanbi" @click="close"></i>
            </div>
        </div>
        <div class="tab">
            <div class="tab-item" :class="{ 'act': tabAct == 1 }" @click.stop="tabAct = 1">{{ $t('Notification.news') }}
            </div>
            <div class="tab-item" :class="{ 'act': tabAct == 2 }" @click.stop="tabAct = 2">{{
                $t('Notification.Notification') }}</div>
        </div>
        <div class="tab-content">
            <div v-if="tabAct == 1" class="main_content">
                <div class="list" v-if="noticeList.length">
                    <div class="list_items" v-for="(item, index) in noticeList" :key="index" @click="toDetail(item)">
                        <div class="top_box">
                            <div class="time">{{ item.createTime }}</div>
                            <div class="title">
                                {{ item.title }}
                            </div>
                        </div>
                        <div class="info-wrap">{{ item.introduction }}</div>
                    </div>
                    <div class="load_txt">
                        <span>{{ $t("Notification.nomore") }}</span>
                    </div>
                </div>
                <div v-else class="nodata">
                    <img src="@/assets/common/empty.png" />
                    <div class="empty">{{ $t("lang.empty") }}</div>
                </div>
            </div>
            <div v-if="tabAct == 2" class="main_content"></div>
        </div>
        <div class="main_content" v-if="tabAct == 2">
            <div class="list" v-if="NoticationList.length">
                <div class="list_items" v-for="(item, index) in NoticationList" :key="index"
                    @click="toMsgDetail(item.id)">
                    <div class="top_box">
                        <div class="time">{{ item.createTime }}</div>
                        <div class="title">
                            {{ item.messgeType }}
                        </div>
                    </div>
                    <div class="info-wrap">{{ item.conut }}</div>
                </div>
                <div class="load_txt">
                    <span>{{ $t("Notification.nomore") }}</span>
                </div>
            </div>
            <div v-else class="nodata">
                <img src="@/assets/common/empty.png" />
                <div class="empty">{{ $t("lang.empty") }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { getNotication, getArticleList } from '@/api/notication'
export default {
    name: 'NotificationContent',
    data() {
        return {
            tabAct: 1,
            noticeList: [],
            NoticationList: []
        }
    },
    created() { },
    methods: {
        close() {
            this.$emit('close')
        },
        open() {
            this.getNotication()
            this.getArticleList()
            this.$nextTick(() => { })
        },
        getArticleList() {
            getArticleList({ id: 2 }).then(res => {
                if (res?.code) return
                const D = res?.result
                this.noticeList = D
            })
        },
        getNotication() {
            getNotication().then(res => {
                if (res?.code) return
                const D = res?.result
                this.NoticationList = D.value1
            })
        },
        toDetail(D) {
            const obj = {
                id: D.id,
                eventName: D.title,
                createTime: D.createTime,
                eventContent: D.content
            }
            const params = encodeURIComponent(JSON.stringify(obj))
            const timestamp = new Date().getTime()
            this.onNavTo({ url: 'PromotionCustom', type: 'post', params: { timestamp, params } })
        },
        toMsgDetail(id) {
            const params = encodeURIComponent(JSON.stringify({ id }))
            const timestamp = new Date().getTime()
            this.onNavTo({ url: 'MsgDetail', type: 'post', params: { timestamp, params } })
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';
.content {
    width: 100%;
    height: 100%;
    background: $bg-color;
    box-sizing: border-box;
    overflow: auto;

    .header {
        display: flex;
        height: 1rem;
        padding-left: .3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: .1rem;
        background: $bg-popup-navbar-color;

        &>div:first-child {
            font-size: .28rem;
            font-weight: bold;
            color: #fff;
        }

        .close {
            color: $font-iconfont-color;
            font-size: .36rem;
            font-weight: bold;
            height: 1rem;
            width: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }

    .tab {
        transform: translateZ(0);
        box-sizing: content-box;
        display: flex;
        color: $font-tab-color;
        background: linear-gradient(81deg, rgba(38,34,40,0.88), rgba(85,92,92,0.88), rgba(46,48,52,0.88), rgba(89,93,96,0.88), rgba(37,46,64,0.88));
        font-size: 14px;
        width: 100%;
        height: 50px;

        .tab-item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            cursor: pointer;

            &.act {
                color: $font-tab-act-color;
                font-weight: 600;
                background: $bg-switch-act-color;
                border-bottom: .04rem solid $font-theme-color;
            }
        }
    }

    .main_content {
        padding: 0 .2rem;
        flex: 1;
        overflow: hidden auto;

        .list_items {
            border-radius: .14rem;
            box-sizing: border-box;
            position: relative;
            margin-top: .2rem;
            border: .02rem solid $border-color;
            background: $bg-box-color;
            padding: .2rem;
            cursor: pointer;

            .top_box {
                // display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .title {
                cursor: pointer;
                font-size: .24rem;
                color: $font-title-color;
                max-width: 100%;
                text-align: left;
                word-wrap: break-word;
            }

            .time {
                font-size: .19rem;
                color: $font-sub-color;
                text-align: left;
            }

            .info-wrap {
                margin-top: .2rem;
                font-size: .22rem;
                color: $font-label-color;
                line-height: 1.3;
                text-align: left;
                cursor: pointer;
            }
        }

        .load_txt {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            font-family: Poppins;
            font-style: normal;
            // font-weight: 600;
            font-size: .24rem;
            margin-top: .6rem;
            color: $font-empty-color;
        }

        .nodata {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 3rem;

            img {
                width: 100%;
            }

            .empty {
                margin-top: .4rem;
                text-align: center;
                color: $font-empty-color;
                font-size: .24rem;
            }
        }
    }
}
</style>
