<template>
    <div class="popup" :class="classObj">
        <van-popup :value="show" @click-overlay="close" @open="open">
            <div class="content">
                <div class="header">
                    <div>{{ $t("signin.checkin") }}</div>
                    <div class="close">
                        <i class="iconfont icon-s-cuowu-guanbi" @click="close"></i>
                    </div>
                </div>
                <div class="box">
                    <div class="signin_list" v-if="signinList.length">
                        <div class="signin_item" v-for="(item, index ) in signinList" :key="index"
                            :class="{ 'ingBox': item.whichDay == info.signDay }">
                            <div class="mask" v-if="item.whichDay < info.durationDay"></div>
                            <div class="info">
                                <div class="money_type">
                                    {{ CurrencyType }}
                                </div>
                                <div class="money">
                                    +{{ item.bonusAmt }}
                                </div>
                                <div class="day">{{ item.whichDay === info.signDay ? $t('signin.Today') :
                                    $t('signin.day') + item.whichDay }}
                                </div>
                            </div>
                            <div class="nowDay">
                                {{ item.whichDay === info.signDay ? $t('signin.Today') :
                                    $t('signin.day') + item.whichDay }}
                            </div>
                        </div>
                    </div>
                    <div class="btn" @click="toPendingPop" :class="{ act: todayCheckStatus === 'canBeCompleted' }">
                        {{ todayCheckStatusText }}
                    </div>
                    <div class="condition">
                        <div class="title">
                            {{ $t("signin.Conditions") }}
                        </div>
                        <ul>
                            <li>
                                {{ $t('signin.condition1') }}:{{ info.dailyRechargeAmt }}/{{ info.rechargeAmtGreaterThan
                                }}
                            </li>
                            <li>
                                {{ $t('signin.condition2') }}:{{ info.dailyBettingAmt }}/{{ info.bettingAmtGreaterThan
                                }}
                            </li>
                        </ul>
                    </div>
                    <div class="rule_box">
                        <div class="title">
                            {{ $t("signin.Rules") }}
                        </div>
                        <div class="rule" v-html="rulesDesc"></div>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { getDailyCheckInInfo } from '@/api/signin'
import {
    getEventsHomeShow
} from '@/api/home'
export default {
    name: 'checkIn',
    components: {},
    data() {
        return {
            firstLoaded: true,
            signinList: [],
            todayCheckStatus: '',
            rulesDesc: '',
            bonusAmt: 0,
            info: {
                durationDay: 0, // 连续签到天数
                dailyRechargeAmt: 0, // 当日充值金额
                rechargeAmtGreaterThan: 0, // 当日充值条件
                dailyBettingAmt: 0, // 当日下注金额
                bettingAmtGreaterThan: 0, // 当日下注条件
                signDay: 0 // 今天签到的是第几天
            }
        }
    },
    computed: {
        isLogin() {
            return !!this.$store.getters.token
        },
        ...mapState({
            device: state => state.app.device
        }),
        classObj() {
            return {
                mobile: this.device === 'mobile'
            }
        },
        eventId() {
            return this.$store.state.user.checkInEventId
        },
        show: {
            get() {
                return this.$store.getters.isShowCheckInPopup
            },
            set(val) {
                return val
            }
        },
        todayCheckStatusText() {
            if (this.todayCheckStatus === 'uncompleted') {
                return this.$t('signin.Uncompleted')
            } else if (this.todayCheckStatus === 'complete') {
                return this.$t('signin.Completed')
            } else if (this.todayCheckStatus === 'canBeCompleted') {
                return this.$t('signin.CheckIn')
            } else {
                return ''
            }
        }
    },
    created() {
    },
    methods: {
        open() {
            if (this.firstLoaded) {
                this.init()
            }
        },
        close() {
            this.$store.dispatch('user/setIsShowCheckInPopup', { id: '', show: false })
        },
        init() {
            this.getInfo()
        },
        getInfo() {
            if (this.eventId) {
                this.getSignInInfo(this.eventId)
            } else {
                getEventsHomeShow().then((res) => {
                    if (res.code) return
                    const D = res.result.eventManageList
                    const item = D.filter(item => item.eventType === 'signIn')
                    this.getSignInInfo(item[0].id)
                })
            }
        },
        getSignInInfo(id) {
            getDailyCheckInInfo({ id }).then((res) => {
                if (res.code) return
                const D = res.result
                this.signinList = D.value0.singInModelDetailVoList // 签到列表
                this.rulesDesc = D.value0.eventContent // 规则说明
                this.info.durationDay = D.value1.durationDay // 连续签到天数
                this.todayCheckStatus = D.value1.todayCheckStatus // 当日签到状态
                this.info.signDay = D.value1.nextDay // 今天签到的是第几天
                this.info.rechargeAmtGreaterThan = this.signinList[this.info.signDay].rechargeAmtGreaterThan // 当日充值条件
                this.info.bettingAmtGreaterThan = this.signinList[this.info.signDay].bettingAmtGreaterThan // 当日下注条件
                this.info.dailyRechargeAmt = D.value1.dailyRechargeAmt // 当日充值金额
                this.info.dailyBettingAmt = D.value1.dailyBettingAmt // 当日下注金额
                this.bonusAmt = D.value1.dailyCheckInAmt
                this.firstLoaded = false
                // this.bonusAmt = this.signinList[this.info.signDay - 1].bonusAmt
                // this.info.signDay = D.eventDailyCheckInDetailVo.whichDay // 第几天
            })
        },
        toPendingPop() {
            if (this.todayCheckStatus === 'canBeCompleted') {
                this.$store.dispatch('user/setIsShowPendingPopup', true)
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.popup {
    &.mobile {

        .van-popup {
            &.van-popup--center {
                width: 82.6% !important;
                height: max-content !important;
                max-width: 9rem !important;
                min-height: 8.6rem !important;
                max-height: 64.5% !important;
            }

            .content {
                width: 100% !important;
                padding-bottom: .4rem;

                .header {
                    height: .94rem;
                    padding: 0 .28rem;

                    &>div:first-child {
                        font-size: .3rem;
                    }

                    .close {
                        font-size: .3rem;
                    }
                }

                .box {
                    padding: .4rem .4rem 0;

                    .signin_list {
                        gap: .12rem;
                        margin-bottom: .2rem;

                        .signin_item {
                            width: calc((100% - 0.36rem)/4);
                            height: max-content;
                            padding: 0 .1rem;

                            .info {
                                height: 17.32vw;
                            }

                            .money_type {
                                width: .64rem;
                                height: .3rem;
                                line-height: .3rem;
                                border-radius: .28rem;
                                font-size: .28rem;
                            }

                            .day {
                                font-size: .22rem;
                            }

                            .money {
                                font-size: .46rem;
                                line-height: .46rem;
                                margin: .04rem 0;
                            }

                            .nowDay {
                                font-size: .26rem;
                                padding: .08rem 0 .12rem;
                                color: $font-sub-color;
                                line-height: .3rem;
                            }
                        }
                    }

                    .btn {
                        height: .8rem;
                        line-height: .8rem;
                        font-size: .28rem;
                    }

                    .condition {
                        margin: .2rem 0;
                        font-size: .2rem;

                        ul {
                            padding-left: .2rem;
                        }
                    }

                    .rule_box {
                        // border: .02rem solid $border-color;
                        font-size: .2rem;
                        padding: .2rem;

                        .title {
                            font-size: .24rem;
                            margin-bottom: .2rem;
                        }
                    }
                }
            }
        }
    }

    .van-popup {
        background: transparent;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .content {
            background: $bg-popup-color;
            width: 500px;
            height: 700px;
            border-radius: .24rem;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            padding-bottom: 30px;

            .header {
                display: flex;
                height: 76px;
                padding: 0 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: $bg-popup-navbar-color;

                &>div:first-child {
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                }

                .close {
                    color: $font-iconfont-color;
                    font-size: 16px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: all 0.3s;

                    &:hover {
                        color: #ffffff;
                        rotate: -180deg;
                    }
                }
            }

            .box {
                flex: 1;
                display: flex;
                flex-direction: column;
                padding: 20px 30px 0;
                overflow-y: scroll;

                .signin_list {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    gap: 10px;
                    margin-bottom: 30px;

                    .signin_item {
                        width: 101px;
                        // width: 102.5px;
                        height: 130.5px;
                        text-align: center;
                        background: $bg-menu-color;
                        border-radius: .24rem;
                        position: relative;
                        padding: 0 10px;
                        overflow: hidden;

                        &:last-child {
                            flex: 1;
                        }

                        .mask {
                            position: absolute;
                            border-radius: .24rem;
                            width: 100%;
                            height: 100%;
                            background-color: rgba(0, 0, 0, 0.5);
                        }

                        .info {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            height: 97.5px;
                            background: rgba(129, 106, 166, 0.08);
                        }

                        .money_type {
                            width: .8rem;
                            height: .4rem;
                            line-height: .4rem;
                            background: #fdab35;
                            border-radius: 20px;
                            color: #442905;
                            text-align: center;
                            font-size: 20px;
                        }

                        .day {
                            color: #e0b95f;
                            font-size: 12px;
                            font-weight: bold;
                        }

                        .money {
                            background: linear-gradient(0deg, #FF940A 0%, #FAC988 100%);
                            background-clip: text;
                            -webkit-background-clip: text;
                            color: transparent;
                            font-weight: bold;
                            font-size: 32px;
                            line-height: 32px;
                            margin: .1rem 0;
                        }

                        .nowDay {
                            font-size: 14px;
                            padding: 6px 0 10px;
                            color: $font-sub-color;
                            line-height: 17px;
                        }

                        &.ingBox {
                            .nowDay {
                                color: $font-theme-color;
                            }

                            // background-color: rgb(48, 48, 48);
                            // box-shadow: 0 0 0.1rem 0.04rem rgba(255, 255, 255, 0.5);
                        }
                    }
                }

                .btn {
                    height: 46px;
                    line-height: 46px;
                    border-radius: .24rem;
                    background: $bg-join-btn;
                    text-align: center;
                    font-size: 14px;
                    color: $font-color;
                    font-weight: bold;
                    cursor: pointer;
                    opacity: 0.3;
                    cursor: not-allowed;

                    &.act {
                        cursor: pointer;
                        opacity: 1;
                    }
                }

                .condition {
                    margin: 10px 0;
                    font-size: 14px;
                    color: $font-sub-color;
                    text-align: left;

                    ul {
                        list-style: disc;
                        margin-bottom: 0;
                        padding-left: 10px;
                    }
                }

                .rule_box {
                    // border: 2px solid $border-color;
                    color: $font-sub-color;
                    font-size: 12px;
                    text-align: left;
                    border-radius: .24rem;
                    padding: 10px;
                    background: $bg-menu-color;

                    .title {
                        font-size: 16px;
                        margin-bottom: 10px;
                        font-weight: bold;
                        background: linear-gradient(0deg, #FF940A 0%, #FAC988 100%);
                        -webkit-background-clip: text;
                        background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }

                    .rule {
                        color: #ffffff;
                    }
                }
            }
        }
    }
}
</style>
