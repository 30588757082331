<template>
    <div class="popup" :class="classObj">
        <van-popup :value="show" @click-overlay="close" @open="open">
            <div class="content">
                <div class="header">
                    <div>{{ $t("lossSupport.title") }}</div>
                    <div class="close">
                        <i class="iconfont icon-s-cuowu-guanbi" @click="close"></i>
                    </div>
                </div>
                <div class="box">
                    <div class="main_content">
                        <div class="info">
                            <div class="item">
                                <div>{{ $t('lossSupport.amountDamage') }}</div>
                                <div>{{ CurrencyType }} {{ info.grandTotalLoss || 0 }}</div>
                            </div>
                            <div class="item">
                                <div>{{ $t('lossSupport.AmountReward') }}</div>
                                <div>{{ CurrencyType }} {{ info.amount || 0 }}</div>
                            </div>
                        </div>
                        <div class="day-body">
                            <div class="table">
                                <div class="tHeader">
                                    <div v-if="rewardType == 'memberLevel'">{{ $t('lossSupport.VIPLevel') }}</div>
                                    <div v-else>{{ $t('lossSupport.loseMoney') }}</div>
                                    <div>{{ $t('lossSupport.extraprizes') }}</div>
                                </div>
                                <div class="tBody">
                                    <div v-if="list.length">
                                        <div class="tr" v-for="(item, index) in list" :key="index">
                                            <div v-if="rewardType == 'memberLevel'">{{ item.memberLevelName }}</div>
                                            <div v-else>≥{{ item.lossAmt }}</div>
                                            <div>
                                                <span v-if="rewardType == 'immobilization'"
                                                    style="margin-right: 6px;">{{ CurrencyType }}</span>
                                                <span>{{ item.rewardsAmt }}</span>
                                                <span
                                                    v-if="rewardType == 'scale' || rewardType == 'memberLevel'">%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="empty" v-else>
                                        <img src="@/assets/common/empty.png" alt="" class="empty_img">
                                        <div>{{ $t("lang.NoData") }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="intro">
                                <div class="title">{{ $t('lossSupport.EventIntro') }}</div>
                                <div class="desc" v-html="rulesDesc"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { getMemberEventLossInfo } from '@/api/lossSupport'
export default {
    name: 'lossSupport',
    components: {},
    data() {
        return {
            info: {
                grandTotalLoss: 0,
                amount: 0
            },
            rewardType: 'scale',
            rulesDesc: '',
            list: [],
            title: ''
        }
    },
    computed: {
        isLogin() {
            return !!this.$store.getters.token
        },
        ...mapState({
            device: state => state.app.device
        }),
        classObj() {
            return {
                mobile: this.device === 'mobile'
            }
        },
        eventId() {
            return this.$store.state.user.LossSupportEventId
        },
        show: {
            get() {
                return this.$store.getters.isShowLossSupportPopup
            },
            set(val) {
                return val
            }
        }
    },
    created() {
    },
    methods: {
        open() {
            this.init()
        },
        close() {
            this.$store.dispatch('user/setIsShowLossSupportPopup', { id: '', show: false })
        },
        init() {
            const eventId = this.eventId
            this.getInfo(eventId)
        },
        getInfo(id) {
            const parem = {
                id: id
            }
            getMemberEventLossInfo(parem).then((res) => {
                if (res.code) return
                const D = res.result
                this.rulesDesc = D.value0.eventContent // 规则说明
                this.title = D.value0.eventName
                this.list = D.value0.reliefMoneyDetailsList
                this.rewardType = D.value0.rewardType // 奖励类型,可用值:scale比例,immobilization固定
                this.info = D.value1
            })
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.popup {
    &.mobile {

        .van-popup {
            &.van-popup--center {
                width: 88.6% !important;
                height: max-content !important;
                max-width: 9rem !important;
                min-height: 8.6rem !important;
                max-height: 64.5% !important;
            }

            .content {
                width: 100% !important;
                padding-bottom: .4rem;

                .header {
                    height: .94rem;
                    padding: 0 .28rem;

                    &>div:first-child {
                        font-size: .3rem;
                    }

                    .close {
                        font-size: .3rem;
                    }
                }

                .box {
                    padding: .3rem .3rem 0;

                    .main_content {
                        .info {
                            .item {
                                font-size: .24rem;

                                >div {

                                    &:last-child {
                                        font-size: .3rem;
                                    }
                                }
                            }
                        }

                        .table {
                            .tHeader {
                                height: .74rem;
                                font-size: 0.24rem;
                            }

                            .tBody {
                                min-height: 5rem;
                                .tr {
                                    height: .74rem;

                                    &>div {
                                        font-size: 0.24rem;
                                    }
                                }

                                .empty {
                                    font-size: .28rem;

                                    .empty_img {
                                        width: 3.4rem;
                                        object-fit: contain;
                                    }
                                }
                            }
                        }

                        .intro {
                            border: .02rem solid $border-color;
                            font-size: .2rem;
                            padding: .2rem;

                            .title {
                                font-size: .24rem;
                                margin-bottom: .2rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .van-popup {
        background: transparent;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .content {
            background: $bg-popup-color;
            width: 500px;
            height: 700px;
            border-radius: .24rem;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            padding-bottom: 30px;

            .header {
                display: flex;
                height: 76px;
                padding: 0 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: $bg-popup-navbar-color;

                &>div:first-child {
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                }

                .close {
                    color: $font-iconfont-color;
                    font-size: 16px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: all 0.3s;

                    &:hover {
                        color: #ffffff;
                        rotate: -180deg;
                    }
                }
            }

            .box {
                flex: 1;
                display: flex;
                flex-direction: column;
                padding: 20px 20px 0;
                overflow-y: scroll;

                .main_content {

                    .info {
                        display: flex;
                        justify-content: space-between;

                        .item {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            width: 48.5%;
                            height: 75px;
                            background: $bg-menu-color;
                            border-radius: .24rem;
                            color: $font-sub-color;
                            font-size: 15px;
                            text-align: center;

                            >div:last-child {
                                font-size: 23px;
                                color: #fff;
                            }
                        }
                    }

                    .day-body {
                        flex: 1;
                        overflow-y: auto;
                        // padding-bottom: 1rem;
                    }

                    .table {
                        border-radius: 10px;
                        margin: 15px 0 18px;
                        background: $bg-menu-color;
                        padding: 0.25rem;

                        .tHeader {
                            color: #fff;
                            font-size: 17px;
                            height: 1rem;
                            display: flex;
                            align-items: center;
                            // padding: 0 28px;
                            border: 1px solid $border-color;
                            border-radius: .24rem;

                            &>div {
                                width: 50%;
                                text-align: center;
                            }
                        }

                        .tBody {
                            // padding: 0 28px;
                            min-height: 321px;
                            position: relative;

                            .tr {
                                display: flex;
                                align-items: center;
                                height: .8rem;

                                &:nth-child(2n) {
                                    background: $bg-box-color;
                                    border-radius: 12px;
                                }

                                &>div {
                                    width: 50%;
                                    color: $font-sub-color;
                                    font-size: 15px;
                                    text-align: center;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }
                            }

                            .empty {
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                color: $font-sub-color;
                                font-size: 16px;
                                text-align: center;

                                .empty_img {
                                    width: 170px;
                                    object-fit: contain;
                                }
                            }
                        }
                    }

                    .intro {
                        border: 2px solid $border-color;
                        color: $font-sub-color;
                        font-size: 12px;
                        text-align: left;
                        border-radius: .24rem;
                        padding: 10px;

                        .title {
                            font-size: 16px;
                            margin-bottom: 10px;
                            font-weight: bold;
                            background: linear-gradient(0deg, #FF940A 0%, #FAC988 100%);
                            -webkit-background-clip: text;
                            background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }

                        .desc {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}
</style>
