<template>
    <div style="position: relative;" v-if="show">
        <div class="language" @click.stop="onShowLangPopup">
            <div class="language_icon" v-if="showIcon">
                <img mode="aspectFill" :src="langObj[langType].img" />
            </div>
            <slot name="langType"></slot>
        </div>
        <div v-if="isShowLangPopup" class="mask" @click.stop="isShowLangPopup = false"></div>
        <div v-if="isShowLangPopup" class="lang_content"
            :class="{ 'langs__up': isUp, 'langs__up-show': (isUp && isShowLangPopup) }"
            :style="{ right: contentRight, top: contentTop }">
            <div class="langs">
                <div v-for="(item, index) in langList" :key="index" class="outset_item"
                    :class="{ 'act': langType === item.type }" @click.stop="onSelLang(item.type)">
                    <div class="langs__item">
                        <div class="langs__item__img">
                            <van-image width="25px" height="25px" radius="50%" mode="aspectFill"
                                :src="item.img"></van-image>
                        </div>
                        <div class="txt">{{ item.name }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'language',
    data() {
        return {
            isShowLangPopup: false,
            langList: []
        }
    },
    computed: {
        langType() {
            return this.$store.getters.language
        }
    },
    props: {
        contentRight: {
            type: String,
            default: '0'
        },
        contentTop: {
            type: String,
            default: 'calc(-100% + 1.5rem)'
        },
        isUp: {
            type: Boolean,
            default: false
        },
        show: {
            type: Boolean,
            default: false
        },
        showIcon: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    created() {},
    methods: {
        onShowLangPopup() {
            this.langList = []
            let Arr = []
            const List = this.$store.getters.sysConfig?.countryList
            if (List) Arr = List.split(',')
            if (Arr && Arr.length) {
                Arr.forEach(x => {
                    const obj = this.langObj[x]
                    if (obj) this.langList.push(obj)
                })
            }
            if (!this.disabled) this.isShowLangPopup = true
            if (this.DisabledBelongCountry === 'disable') this.isShowLangPopup = false
            if (this.DisabledBelongCountry !== 'disable') this.isShowLangPopup = true
            if (!this.langList.length) this.isShowLangPopup = false
            this.$forceUpdate()
        },
        onSelLang(type) {
            this.$store.dispatch('system/setLanguage', type)
            this.$store.dispatch('system/setUserSetLanguage', type)
            this.$i18n.locale = type
            this.isShowLangPopup = false
            if (this.$route.path.indexOf('/Home') !== -1) {
                const opt = this.$route?.query
                if (opt && opt.token) {
                    this.onNavTo({ url: '/Home', replace: 1 })
                } else {
                    window.location.reload()
                }
            } else {
                this.onNavTo({ url: '/Home', replace: 1 })
            }
            this.$emit('confirm')
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';
.language {
    width: .64rem;
    height: .64rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .language_icon {
        width: .42rem;
        height: .42rem;
        border-radius: 50%;

        &>img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            overflow: hidden;
            vertical-align: top;
        }
    }
}

.mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .55);
    z-index: 998;
}

.lang_content {
    padding: .1rem;
    box-sizing: border-box;
    width: 2.7rem;
    background: $bg-popup-color;
    border: .01rem solid transparent;
    border-radius: .16rem;
    position: absolute;
    z-index: 999;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            font-weight: 400;
            font-size: .22rem;
            color: #fff;
        }

        .close_btn {
            width: .42rem;
            height: .42rem;
            cursor: pointer;
        }
    }

    .langs {

        .outset_item {
            border-radius: .16rem;

            .langs__item {
                width: 100%;
                height: .8rem;
                background: transparent;
                border-radius: .16rem;
                display: flex;
                align-items: center;
                padding: 0 .2rem;
                cursor: pointer;
                box-sizing: border-box;

                &__img {
                    width: .4rem;
                    height: .4rem;
                    margin-right: .2rem;
                }

                .txt {
                    font-style: normal;
                    font-weight: 400;
                    font-size: .23rem;
                    color: #C1C1C1;
                }
            }

            &.act {
                // background: #42FFDB;

                .langs__item {
                    background: $bg-pop-lang-active;
                }

                .txt {
                    color: #fff;
                }
            }
        }
    }

    &.langs__up {
        transform-origin: 0 100%;
        top: -5px !important;
        transform: translate3d(0, -100%, 0) scaleY(0);
    }

    &.langs__up-show {
        transform: translate3d(0, -100%, 0) scaleY(1);
        visibility: visible;
        opacity: 1;
    }
}
</style>
