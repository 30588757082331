<template>
    <div class="pop" :class="classObj">
        <van-popup :value="show" :position="showType" @click-overlay="close" @open="open">
            <div class="content">
                <i class="iconfont icon-s-cuowu-guanbi" @click="close"></i>
                <div class="header">
                    <div>{{ $t("home.Search") }}</div>
                    <div class="close">
                        <i class="iconfont icon-s-cuowu-guanbi" @click="close"></i>
                    </div>
                </div>
                <div class="gameSearchBox">
                    <div class="search_box">
                        <a-input-search v-model="keyword" :placeholder="$t('home.Search')" @search="onSearch"
                            allowClear>
                            <i slot="prefix" class="iconfont icon-sousuo" @click="onSearch" />
                        </a-input-search>
                    </div>
                    <div class="filter_box">
                        <div class="filter">
                            <div class="nowType" :class="{ act: showProvidersList }" @click="showList('providers')">
                                <div>{{ providerAct === -1 ? $t("home.Providers") :
                                    providersList[providerAct].gamePlatformType }}</div>
                                <i class="iconfont icon-open" :class="{ act: showProvidersList }"></i>
                            </div>
                        </div>
                        <div class="filter">
                            <div class="nowType" :class="{ act: showCategoryList }" @click="showList('category')">
                                <div>{{ categoryAct === -1 ? $t("home.Categories") :
                                    categoryList[categoryAct].categoryShowName }}</div>
                                <i class="iconfont icon-open" :class="{ act: showCategoryList }"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="typeBox" :class="{ act: showProvidersList || showCategoryList }">
                    <div class="box" v-show="showProvidersList">
                        <div class="type_name" :class="{ act: index === providerAct }"
                            v-for="(item, index) in providersList" :key="index"
                            @click="onFitlterGames(index, categoryAct)">
                            <div class="circle"></div>
                            {{ item.gamePlatformType }}
                        </div>
                    </div>
                    <div class="box" v-show="showCategoryList">
                        <div class="type_name" :class="{ act: index === categoryAct }"
                            v-for="(item, index) in categoryList" :key="index"
                            @click="onFitlterGames(providerAct, index)">
                            <div class="circle"></div>
                            {{ item.categoryShowName }}
                        </div>
                    </div>
                </div>
                <div class="history_box" v-if="history.length">
                    <div class="history_list">
                        <div class="history_item" v-for="(item, index) in history" :key="index" @click="onSearch(item)">
                            {{ item }}
                        </div>
                    </div>
                </div>
                <div class="game_content" v-for="(item, index) in gameClassify" :key="index" :id="item.id">
                    <div class="game_list" v-if="item.gameList.length">
                        <div class="game_header">
                            <div>
                                <img :src="item.activationImg" />
                                <div>{{ item.categoryShowName }}</div>
                            </div>
                            <div>
                                <div class="more" @click.stop="toCasino(item)"
                                    v-if="item.gameCategoryType !== 'Search'">
                                    {{ $t("home.MORE") }}
                                </div>
                                <div class="left" @click="getGameList(item.gameCategoryType, item.index, 'prev')">
                                    <div class="iconfont icon-xiangyou"></div>
                                </div>
                                <div class="right" @click="getGameList(item.gameCategoryType, item.index, 'next')">
                                    <div class="iconfont icon-xiangyou"></div>
                                </div>
                            </div>
                        </div>
                        <div v-if="item.isLoading" class="loading">
                            <a-spin>
                                <a-icon slot="indicator" type="loading" spin />
                            </a-spin>
                            {{ $t("lang.Loading") }}
                        </div>
                        <van-swipe class="swipeGameBox" :show-indicators="false" :loop="false"
                            v-show="item.outList && item.outList.length && !item.isLoading"
                            @change="onGameSwipeChange($event, item.index)" :ref="`swipe${item.index}`">
                            <van-swipe-item v-for="(oitem, oindex) in item.outList" :key="oindex">
                                <div class="game_box" :class="{ game_less_box: item.gameList.length < 6 }">
                                    <div class="game_item" v-for="(citem, cindex) in oitem" :key="cindex"
                                        @click="openGamePop(citem)">
                                        <div class="game_img">
                                            <div class="game_mask">
                                                <!-- <div class="RTP">
                                                    <span>RTP</span>
                                                    <span>{{ citem.rtp }}%</span>
                                                </div> -->
                                                <div class="game_center">
                                                    <div class="name">{{ citem.gameName }}</div>
                                                    <div class="game_btn" @click.stop="onShowGamePopup(citem)">
                                                        <i class="iconfont icon-shipin"></i>
                                                        <span>{{ $t("lang.Play") }}</span>
                                                    </div>
                                                </div>
                                                <div class="game_collect" @click.stop="addCollect(citem, cindex)">
                                                    <i v-if="activeIds.includes(citem.id)"
                                                        class="iconAct iconfont icon-a-shoucangyishoucang"></i>
                                                    <i v-else class="iconfont icon-a-shoucang_quxiaoshoucang"></i>
                                                </div>
                                            </div>
                                            <img :src="citem.imageUrl" @dragstart.prevent />
                                        </div>
                                        <div class="gameName">{{ citem.gameName }}</div>
                                    </div>
                                </div>
                            </van-swipe-item>
                        </van-swipe>
                    </div>
                    <!-- <div class="empty" v-else>
                        <img src="@/assets/common/empty.png" alt="" class="empty_img">
                        <div>{{ $t("lang.NoData") }}</div>
                    </div> -->
                </div>
            </div>
        </van-popup>
        <gamePop :showPop="isShowGamePop" :info="gamePopInfo" :activeIds="activeIds"
            @activeIdsSplice="activeIds.splice($event, 1)" @activeIdsPush="activeIds.push($event)"
            @close="isShowGamePop = false" @onShowGamePopup="onShowGamePopup">
        </gamePop>
        <gamePopup ref="gamePopup" :params="gameParams"></gamePopup>
    </div>
</template>

<script>
import { Toast } from 'vant'
import { mapState } from 'vuex'
import gamePopup from '@/components/gamePopup'
import gamePop from '@/components/gamePopup/infoPopup'
import {
    addFav,
    removeFav,
    getHotGames,
    getRecentlyGames,
    getGameAllCollect,
    getGameClassify
} from '@/api/game'
import {
    getGamePageByName,
    getHistory
} from '@/api/search.js'
export default {
    name: 'searchPopup',
    components: {
        gamePop,
        gamePopup
    },
    computed: {
        isLogin() {
            return !!this.$store.getters.token
        },
        ...mapState({
            device: state => state.app.device
        }),
        classObj() {
            return {
                mobile: this.device === 'mobile'
            }
        },
        showType() {
            if (this.device === 'mobile') {
                return 'bottom'
            } else {
                return 'center'
            }
        },
        pageSize() {
            if (this.classObj.mobile) {
                return 18
            } else {
                return 14
            }
        },
        chunkGameSize() {
            if (this.classObj.mobile) {
                return 6
            } else {
                return 7
            }
        },
        show: {
            get() {
                return this.$store.state.user.isShowSearchPopup
            },
            set(val) {
                return val
            }
        }
    },
    data() {
        return {
            isDebounced: false,
            firstLoaded: true,
            prekeyword: '',
            keyword: '',
            gameClassify: [],
            gameParams: {
                id: '',
                gameInfoId: '',
                gamePlatformType: '',
                providerType: ''
            },
            history: [],
            activeIds: [],
            isShowGamePop: false,
            gamePopInfo: {},
            providerAct: -1,
            categoryAct: -1,
            showProvidersList: false,
            showCategoryList: false,
            providersList: [],
            categoryList: []
        }
    },
    mounted() { },
    methods: {
        close() {
            this.$store.dispatch('user/setIsShowSearchPopup', false)
        },
        open() {
            if (this.isLogin) this.getGameFavs()
            if (this.firstLoaded) {
                this.init()
            }
        },
        getGameFavs() {
            getGameAllCollect().then((res) => {
                if (res.code) return
                this.activeIds = res.result
            })
        },
        init() {
            if (this.isLogin) this.getHistory()
            this.getClassifyTypes()
            const obj1 = {
                id: 'Search',
                activationImg: require('@/assets/home/icon_searchAct.png'),
                unActivationImg: require('@/assets/home/icon_search.png'),
                categoryShowName: this.$t('home.Search'),
                gameCategoryType: 'Search'
            }
            this.gameClassify.unshift(obj1)
            const obj2 = {
                id: 'Popular',
                activationImg: require('@/assets/home/icon_hotAct.png'),
                unActivationImg: require('@/assets/home/icon_hot.png'),
                categoryShowName: this.$t('home.Popular'),
                gameCategoryType: 'Popular'
            }
            this.gameClassify.push(obj2)
            if (this.classObj.mobile) {
                const obj3 = {
                    id: 'Recente',
                    activationImg: require('@/assets/home/icon_recentAct.png'),
                    unActivationImg: require('@/assets/home/icon_recent.png'),
                    categoryShowName: this.$t('home.Recent'),
                    gameCategoryType: 'Recente'
                }
                this.gameClassify.push(obj3)
            }
            this.gameClassify.forEach((x, i) => {
                x.isLoading = 1
                x.pageNum = 1
                x.pageSize = this.pageSize
                x.hasMore = true
                x.outList = []
                x.gameList = []
                x.index = i
                x.swipeIndex = 0
                x.oriList = []
            })
            for (const x of this.gameClassify) {
                if (x.id === 'Recente') {
                    if (this.isLogin) {
                        this.getRecentlyGames(x.index)
                    }
                } else if (x.id === 'Popular') {
                    getHotGames({
                        pageNo: 1,
                        pageSize: this.gameClassify[1].pageSize
                    }).then((res) => {
                        if (res.code) return
                        const D = res.result
                        this.gameClassify[x.index].gameList = D.list
                        this.gameClassify[x.index].oriList = D.list
                        this.gameClassify[x.index].hasMore = Number(res.result.total) > this.gameClassify[x.index].gameList.length
                        this.gameClassify[x.index].outList = this.paginatedItems(this.gameClassify[x.index].gameList, this.chunkGameSize)
                        this.gameClassify[x.index].isLoading = 0
                        this.firstLoaded = false
                    })
                }
            }
        },
        getClassifyTypes() {
            getGameClassify().then((res) => {
                if (res.code) return
                const D = res.result.filter(x => x.gameTitleIPage && x.gameTitleIPage.records && x
                    .gameTitleIPage.records.length && x.gameCategoryType !== 'None')
                const list = []
                const provideList = []
                this.categoryList = D
                const seenTypes = new Set()
                for (const x of D) {
                    list.push(x.gameTitleIPage.records)
                }
                for (const y of list) {
                    for (const z of y) {
                        if (!seenTypes.has(z.gamePlatformType)) {
                            provideList.push(z)
                            seenTypes.add(z.gamePlatformType)
                        }
                    }
                }
                this.providersList = provideList
            })
        },
        showList(type) {
            if (type === 'providers') {
                this.showCategoryList = false
                this.showProvidersList = !this.showProvidersList
            } else {
                this.showProvidersList = false
                this.showCategoryList = !this.showCategoryList
            }
        },
        onFitlterGames(index1, index2) {
            this.providerAct = index1
            this.categoryAct = index2
            if (index1 === -1 && index2 === -1) return
            if (this.providerAct !== -1 && this.categoryAct === -1) {
                this.gameClassify.forEach((a, b) => {
                    this.gameClassify[b].gameList = a.oriList.filter(game =>
                        game.gamePlatformType === this.providersList[this.providerAct].gamePlatformType
                    )
                    this.gameClassify[b].outList = this.paginatedItems(this.gameClassify[b].gameList, this.chunkGameSize)
                })
            } else if (this.providerAct === -1 && this.categoryAct !== -1) {
                this.gameClassify.forEach((a, b) => {
                    this.gameClassify[b].gameList = a.oriList.filter(game =>
                        game.gameCategoryType === this.categoryList[this.categoryAct].gameCategoryType
                    )
                    this.gameClassify[b].outList = this.paginatedItems(this.gameClassify[b].gameList, this.chunkGameSize)
                })
            } else {
                this.gameClassify.forEach((a, b) => {
                    this.gameClassify[b].gameList = a.oriList.filter(game =>
                        game.gamePlatformType === this.providersList[this.providerAct].gamePlatformType && game.gameCategoryType === this.categoryList[this.categoryAct].gameCategoryType
                    )
                    this.gameClassify[b].outList = this.paginatedItems(this.gameClassify[b].gameList, this.chunkGameSize)
                })
            }
        },
        async addCollect(item) {
            if (!this.isDebounced) {
                this.isDebounced = true
                const that = this
                if (that.activeIds.includes(item.id)) {
                    const index = that.activeIds.indexOf(item.id)
                    if (index !== -1) {
                        await removeFav({
                            id: item.id
                        })
                            .then((res) => {
                                if (res.code === 0) {
                                    that.activeIds.splice(index, 1)
                                    Toast(that.$t('home.CollectionCanceled'))
                                }
                            })
                    }
                } else {
                    await addFav({
                        id: item.id
                    })
                        .then((res) => {
                            if (res.code === 0) {
                                that.activeIds.push(item.id)
                                Toast(that.$t('home.CollectionSucc'))
                            }
                        })
                }
                setTimeout(() => {
                    this.isDebounced = false
                }, 500)
            } else {
                console.log('111')
            }
        },
        onShowGamePopup(item) {
            this.onNavToByIsLogin({}).then(() => {
                this.gameParams = {
                    id: item.gameId,
                    gameInfoId: item.id,
                    gamePlatformType: item.gamePlatformType,
                    providerType: item.providerType
                }
                this.$refs.gamePopup.open()
                // this.isShowGamePopup = true
            })
        },
        async getHistory() {
            await getHistory()
                .then((res) => {
                    const {
                        result,
                        code
                    } = res
                    if (code) return
                    this.history = result
                })
        },
        getGameList(type, index, status) {
            const that = this
            if (status && status === 'prev') {
                that.$refs[`swipe${index}`][0].prev()
                return
            }
            if (that.gameClassify[index].swipeIndex === that.gameClassify[index].outList.length - 1 && that.gameClassify[index].hasMore) {
                if (type === 'Popular') {
                    this.gameClassify[index].pageNum++
                    this.gameClassify[index].isLoading = 1
                    const params = {
                        pageNo: this.gameClassify[index].pageNum,
                        pageSize: this.gameClassify[index].pageSize
                    }
                    getHotGames(params).then((res) => {
                        that.gameClassify[index].gameList = [...that.gameClassify[index].gameList, ...res.result.list]
                        that.gameClassify[index].hasMore = Number(res.result.total) > that.gameClassify[index].gameList.length
                        const newOutList = that.paginatedItems(res.result.list, that.chunkGameSize)
                        that.gameClassify[index].outList = [...that.gameClassify[index].outList, ...newOutList]
                        // const newOutList = that.paginatedItems(that.gameClassify[index].gameList, that.chunkGameSize)
                        // that.gameClassify[index].outList = newOutList
                        that.onFitlterGames(that.providerAct, that.categoryAct)
                        that.$refs[`swipe${index}`][0].swipeTo(that.gameClassify[index].swipeIndex, { immediate: true })
                        that.gameClassify[index].isLoading = 0
                    })
                } else if (type === 'Recente') {
                    that.gameClassify[index].pageNum++
                    that.gameClassify[index].isLoading = 1
                    const curData = {
                        pageNo: that.gameClassify[index].pageNum,
                        pageSize: that.gameClassify[index].pageSize
                    }
                    getRecentlyGames(curData).then((res) => {
                        const {
                            result,
                            code
                        } = res
                        if (code) return
                        const list = result.list.filter(x => x && x.gameId)
                        list.forEach(x => {
                            x.id = x.gameInfoId
                        })
                        that.gameClassify[index].gameList = [...that.gameClassify[index].gameList, ...list]
                        const newOutList = that.paginatedItems(list, that.chunkGameSize)
                        that.gameClassify[index].outList = [...that.gameClassify[index].outList, ...newOutList]
                        that.onFitlterGames(that.providerAct, that.categoryAct)
                        that.$refs[`swipe${index}`][0].swipeTo(that.gameClassify[index].swipeIndex, { immediate: true })
                        that.gameClassify[index].hasMore = Number(result.total) > that.gameClassify[index].gameList.length
                        that.gameClassify[index].isLoading = 0
                    })
                } else if (type === 'Search') {
                    that.gameClassify[index].pageNum++
                    that.gameClassify[index].isLoading = 1
                    const params = {
                        gameName: this.keyword,
                        pageNo: this.gameClassify[index].pageNum,
                        pageSize: this.gameClassify[index].pageSize
                    }
                    getGamePageByName(params).then((res) => {
                        const {
                            result,
                            code
                        } = res
                        if (code) return
                        const {
                            list,
                            total
                        } = result
                        this.gameClassify[index].gameList = [...this.gameClassify[index].gameList, ...list]
                        const newOutList = this.paginatedItems(list, this.chunkGameSize)
                        this.gameClassify[index].outList = [...this.gameClassify[index].outList, ...newOutList]
                        that.onFitlterGames(that.providerAct, that.categoryAct)
                        that.$refs[`swipe${index}`][0].swipeTo(that.gameClassify[index].swipeIndex, { immediate: true })
                        this.gameClassify[index].hasMore = Number(total) > this.gameClassify[index].gameList.length
                        this.gameClassify[index].isLoading = 0
                    })
                }
            } else {
                this.$refs[`swipe${index}`][0].next()
            }
        },
        async getRecentlyGames(index) {
            const that = this
            const curData = {
                pageNo: that.gameClassify[index].pageNum,
                pageSize: that.gameClassify[index].pageSize
            }
            getRecentlyGames(curData).then((res) => {
                const {
                    result,
                    code
                } = res
                if (code) return
                const list = result.list.filter(x => x && x.gameId)
                list.forEach(x => {
                    x.id = x.gameInfoId
                })
                that.gameClassify[index].gameList = list
                that.gameClassify[index].oriList = list
                that.gameClassify[index].outList = that.paginatedItems(list, that.chunkGameSize)
                that.onFitlterGames(that.providerAct, that.categoryAct)
                that.gameClassify[index].hasMore = Number(result.total) > that.gameClassify[index].gameList.length
                setTimeout(() => {
                    if (list.length !== 0) {
                        that.$refs[`swipe${index}`][0].resize()
                    }
                }, 500)
                that.gameClassify[index].isLoading = 0
            })
        },
        paginatedItems(list, size) {
            if (list.length) {
                const chunkSize = size
                const paginated = []
                for (let i = 0; i < list.length; i += chunkSize) {
                    paginated.push(list.slice(i, i + chunkSize))
                }
                this.$forceUpdate()
                return paginated
            }
        },
        onGameSwipeChange(swiperIndex, categoryIndex) {
            this.gameClassify[categoryIndex].swipeIndex = swiperIndex
            if (this.gameClassify[categoryIndex].swipeIndex === (this.gameClassify[categoryIndex].outList.length - 1)) {
                this.getGameList(this.gameClassify[categoryIndex].gameCategoryType, categoryIndex, 'next')
            }
            this.$forceUpdate()
        },
        openGamePop(item) {
            if (!this.classObj.mobile) {
                this.isShowGamePop = false
            } else {
                this.gamePopInfo = item
                this.isShowGamePop = true
            }
        },
        toCasino(item) {
            const obj = {
                iconImg: item.activationImg,
                code: item.gameCategoryType,
                title: item.categoryShowName
            }
            if (item.outList && item.outList.length) {
                obj.gamePlatformType = item.gamePlatformType
                obj.gameTitleId = item.id
            }
            const params = encodeURIComponent(JSON.stringify(obj))
            const timestamp = new Date().getTime()
            this.onNavTo({ url: 'Casino', type: 'post', params: { timestamp, params } })
            this.close()
        },
        onSearch() {
            this.keyword = this.keyword.trim()
            if (!this.keyword) {
                Toast(this.$t('lang.keywordPlaceholder'))
                return
            }
            if (this.prekeyword === this.keyword) return
            this.gameClassify[0].pageNum = 1
            this.gameClassify[0].gameList = []
            this.gameClassify[0].outList = []
            this.gameClassify[0].hasMore = true
            this.prekeyword = this.keyword
            if (!this.history.includes(this.keyword)) {
                this.getHistory()
            }
            const params = {
                gameName: this.keyword,
                pageNo: this.gameClassify[0].pageNum,
                pageSize: this.gameClassify[0].pageSize
            }
            getGamePageByName(params).then((res) => {
                const {
                    result,
                    code
                } = res
                if (code) return
                const {
                    list,
                    total
                } = result
                this.gameClassify[0].gameList = list
                this.gameClassify[0].oriList = list
                const newOutList = this.paginatedItems(list, this.chunkGameSize)
                this.gameClassify[0].outList = newOutList
                this.onFitlterGames(this.providerAct, this.categoryAct)
                this.gameClassify[0].hasMore = Number(total) > this.gameClassify[0].gameList.length
                setTimeout(() => {
                    if (list.length !== 0 && this.$refs.swipe0[0]) {
                        this.$refs.swipe0[0].resize()
                    }
                }, 500)
                this.gameClassify[0].isLoading = 0
            })
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.pop {
    text-align: left;

    .van-popup {
        background: $bg-popup-color;
        border-radius: .24rem;
        width: 70vw;
        max-width: 1414px;
        min-width: 720px;
        min-height: 360px;
    }

    &.mobile {
        .van-popup {
            border-radius: .24rem;
            width: 100%;
            height: 85%;
            max-width: auto;
            min-width: auto;
            min-height: auto;
        }

        .content {
            padding: 0;

            i {
                &.icon-s-cuowu-guanbi {
                    display: none;
                }
            }

            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-align: left;
                font-size: .4rem;
                padding: .24rem .3rem;
                margin: 0;

                i {
                    display: block;
                    position: static;
                    cursor: pointer;
                }
            }

            .gameSearchBox {
                flex-direction: column;
                gap: 0;
            }

            .search_box {
                margin: .1rem .2rem;
                height: .8rem;
                border-radius: .24rem;

                ::v-deep .ant-input {
                    padding: 4px 40px;
                    font-size: 14px;
                }

                i {
                    font-size: .32rem;
                    color: $font-sub-color;
                }
            }

            .filter_box {
                margin: .1rem .2rem;
                height: .8rem;
            }

            .typeBox {
                margin: .12rem .2rem;
            }

            .history_box {
                padding: 0 .2rem;
                margin: 0 0 .5rem;

                .history_list {
                    display: flex;
                    flex-wrap: nowrap;
                    overflow-x: scroll;
                    gap: .12rem;

                    .history_item {
                        height: .6rem;
                        line-height: .6rem;
                        padding: 0 .2rem;
                        font-size: .24rem;
                    }
                }
            }

            .game_list {
                padding-left: .2rem;

                .game_header {
                    padding: 0 .2rem 0 0;
                }
            }

            .empty {
                font-size: .3rem;

                .empty_img {
                    width: 2.8rem;
                }
            }

            .swipeGameBox {
                width: 7.1rem !important;
                overflow: visible;
            }

            .game_box {
                display: grid;
                grid-auto-flow: column;
                grid-template-rows: repeat(2, 2.68rem);
                grid-template-columns: repeat(auto-fill, 2.16rem);
                row-gap: .24rem;
                column-gap: .2rem;
                overflow-x: scroll;
                margin-bottom: .4rem;

                &.game_less_box {
                    grid-template-rows: repeat(1, 2.68rem);
                }

                .game_item {
                    width: 2.16rem;
                    height: 100%;
                    border-radius: 0.24rem;
                    overflow: hidden;

                    &:hover {
                        .game_img {
                            .game_mask {
                                display: none;
                            }
                        }
                    }

                    .game_img {
                        position: relative;
                        width: 100%;
                    }

                    img {
                        width: 100%;
                        height: 2.16rem;
                    }

                    .gameName {
                        height: .52rem;
                        line-height: .52rem;
                        text-align: center;
                        font-size: .2rem;
                        padding: 0 .1rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }

    .content {
        width: 100%;
        height: 100%;
        position: relative;
        padding: 40px;

        i {
            &.icon-s-cuowu-guanbi {
                position: absolute;
                top: 20px;
                right: 20px;
                font-size: 16px;
                color: $font-iconfont-color;
                font-weight: bold;
                cursor: pointer;
            }
        }

        .header {
            text-align: center;
            color: #ffffff;
            font-size: 26px;
            font-weight: bold;
            margin: 16px 0;

            i {
                display: none;
            }
        }

        .gameSearchBox {
            display: flex;
            flex-direction: row;
            gap: .24rem;
        }

        .typeBox {
            margin-top: 6px;
            transform-origin: 0 0;
            transform: scaleY(0);
            opacity: 0;
            transition: all 0.3s ease-in-out;

            &.act {
                transform: scaleY(1);
                visibility: visible;
                opacity: 1;
            }

            .box {
                display: grid;
                gap: .2rem;
                grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

                .type_name {
                    display: flex;
                    align-items: center;
                    column-gap: .16rem;
                    color: $font-sub-color;
                    font-size: 14px;
                    transition: all 0.3s;
                    cursor: pointer;

                    .circle {
                        width: 26px;
                        height: 26px;
                        border-radius: 50%;
                        border: .02rem solid $font-sub-color;
                        opacity: 0.7;
                        position: relative;
                    }

                    &.act {
                        .circle {
                            opacity: 1;

                            &::after {
                                content: '';
                                display: block;
                                position: absolute;
                                width: 50%;
                                height: 50%;
                                border-radius: 50%;
                                background: $font-theme-color;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }
                    }

                    &:hover {
                        background: rgba(255, 255, 255, 0.1);
                        border-radius: .24rem;

                        .circle {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .search_box {
            height: 46px;
            background: $bg-input-color;
            border: .02rem solid $border-input-color;
            border-radius: .24rem;

            ::v-deep .ant-input {
                padding: 4px 40px;
                font-size: 14px;
            }

            ::v-deep .ant-input-suffix {
                display: none;
            }

            i {
                color: $font-sub-color;
            }
        }

        .filter_box {
            flex: 1;
            display: flex;
            gap: .24rem;

            .filter {
                flex: 1;

                .nowType {
                    height: 46px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    column-gap: .24rem;
                    background: $bg-input-color;
                    border: .02rem solid $border-input-color;
                    border-radius: .24rem;
                    padding: 0 .24rem;
                    width: 100%;
                    color: $font-placeholder-color;
                    cursor: pointer;

                    &.act {
                        color: #ffffff;
                        background: $bg-input-act-color;
                        border: .02rem solid $border-active-color;
                    }

                    i {
                        color: $font-iconfont-color;
                        transition: all 0.3s;

                        &.act {
                            color: #ffffff;
                            font-weight: bold;
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }

        .history_box {
            margin-top: 6px;

            .history_list {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;

                .history_item {
                    height: 46px;
                    line-height: 46px;
                    padding: 0 14px;
                    font-size: 14px;
                    color: $font-sub-color;
                    background: rgba(0, 0, 0, .2);
                    border-radius: .24rem;
                    cursor: pointer;
                }
            }
        }

        .game_list {
            .game_header {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: .18rem 0;
                height: 0.44rem;
                line-height: 0.44rem;

                &>div {
                    display: flex;

                    &>img {
                        width: .44rem;
                        height: .44rem;
                        vertical-align: middle;
                        object-fit: contain;
                    }

                    &>div {
                        color: #FFFFFF;
                        font-size: .3rem;
                        font-weight: bold;
                        // margin-left: .2rem;
                    }

                    &:last-child {
                        font-size: .24rem;
                        margin-right: .2rem;

                        >div {
                            &.more {
                                color: #ffffff;
                                font-size: .24rem;
                                background: rgba(255, 120, 45, 0.32);
                                border: .04rem solid $font-theme-color;
                                padding: 0 .2rem;
                                border-radius: .24rem;
                                margin-right: .2rem;
                                font-weight: bold;
                                cursor: pointer;
                            }

                            &.left,
                            &.right {
                                width: .44rem;
                                height: .44rem;
                                border-radius: 50%;
                                background: #2f3134;
                                color: $font-theme-color;
                                position: relative;
                                cursor: pointer;

                                &:hover {
                                    background: #43464a;
                                }

                                &.disabled {
                                    cursor: not-allowed;
                                    // border: .02rem solid #AF887E;
                                    // color: #AF887E;
                                }

                                >.iconfont {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            &.left {
                                margin-right: .06rem;

                                .icon-xiangyou {
                                    transform: rotate(-180deg) translateX(0.08rem);
                                }
                            }

                            &.right {
                                .icon-xiangyou {
                                    transform: translateX(0.1rem);
                                }
                            }
                        }
                    }
                }
            }

            .game_footer {
                .total {
                    display: block;
                    font-size: .22rem;
                    text-align: center;
                    margin-top: .3rem;
                    color: #666;
                }

                .last-text {
                    color: #999;
                    display: block;
                    font-size: .22rem;
                    margin-top: .02rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &>span {
                        margin-right: .08rem;
                    }

                    &>i {
                        font-size: .24rem;
                        vertical-align: middle;
                    }
                }
            }
        }

        .empty {
            margin: 0 auto;
            color: $font-sub-color;
            font-size: .32rem;
            text-align: center;

            .empty_img {
                width: 170px;
                object-fit: contain;
            }
        }

        .swipeGameBox {
            width: 100%;
            // overflow: visible;
        }

        .game_box {
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            gap: calc(10px * 7 / 6);
            overflow-x: scroll;

            .game_item {
                width: calc(100%/7 - 10px);
                height: 100%;
                border-radius: 0.24rem;
                overflow: hidden;
                cursor: pointer;

                &:hover {
                    .game_img {
                        .game_mask {
                            display: block;
                        }
                    }
                }

                .game_img {
                    position: relative;
                    width: 100%;
                    padding-top: 100%;

                    &>img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                    }
                }

                .game_mask {
                    position: absolute;
                    background: rgba(0, 0, 0, .5);
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 3;
                    --tw-backdrop-blur: blur(5px);
                    backdrop-filter: var(--tw-backdrop-blur);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    display: none;

                    .game_center {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        width: 100%;
                        height: 100%;

                        .name {
                            color: #fff;
                            font-size: .2rem;
                            font-weight: bold;
                            line-height: .25rem;
                            height: .58rem;
                            padding: .04rem 0;
                        }

                        .game_btn {
                            background: $bg-btn-color;
                            width: 78%;
                            height: 16.88%;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 20px;
                            color: $font-btn-color;
                            margin-top: .06rem;

                            &>i {
                                font-size: .24rem;
                                margin-right: .1rem;
                            }
                        }
                    }

                    .RTP {
                        padding-top: 5%;
                        font-size: .24rem;
                        margin: 0 4%;
                        color: $font-sub-color;
                        text-align: left;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;

                        &>span:last-child {
                            background: linear-gradient(0deg, #FF940A 0%, #FAC988 100%);
                            -webkit-background-clip: text;
                            background-clip: text;
                            color: transparent !important;
                            margin-left: .08rem;
                        }
                    }

                    .game_collect {
                        position: absolute;
                        height: .5rem;
                        width: .5rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        z-index: 9;
                        right: .04rem;
                        bottom: .03rem;

                        &>i {
                            font-size: .3rem;
                            color: $font-sub-color;

                            &.iconAct {
                                color: #ec0048;
                            }
                        }
                    }
                }

                .gameName {
                    height: .52rem;
                    line-height: .52rem;
                    text-align: center;
                    font-size: .2rem;
                    color: #ffffff;
                    background: #2f3134;
                    padding: 0 .1rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}
</style>
