<template>
    <div id="fixedHeader" class="fixed-header">
        <statusBar></statusBar>
        <div v-if="isShowDownAppNav" class="downApp">
            <div class="icon-close" @click="onCloseDownAppNav">
                <i class="iconfont icon-s-cuowu-guanbi"></i>
            </div>
            <div class="tips">
                <span>{{ $t("home.DownloadTips") }}</span>
                <span class="money">{{ CurrencyType }}100</span>
            </div>
            <div class="btn" @click="onShowDownAppPopup">
                <i class="iconfont icon-shouji"></i>
                <span>{{ $t("home.Download") }}</span>
            </div>
        </div>
        <div class="pages-header">
            <div>
                <!-- <i class="iconfont icon-caidanshouqi" :class="{ 'sidebarOpened': sidebar.opened }"></i> -->
                <img v-if="device == 'mobile'" class="logo" style="height: .75rem;" :src="logoUrl" @click.stop="refresh" />
                <!-- <img v-else class="logo" :src="logoUrl" @click.stop="refresh" /> -->
                <button v-if="device != 'mobile'" class="MuiButtonBase-root" tabindex="0" type="button"
                    @click="toSearch">Search Games<span class="MuiButton-icon"><svg width="24" height="24"
                            viewBox="0 0 24 24" fill="none">
                            <path d="M15 14L18 17" stroke="#C1C1C1" stroke-width="2" stroke-linecap="round"></path>
                            <circle cx="5" cy="5" r="4" transform="matrix(-1 0 0 1 17 6)" stroke="#C1C1C1" stroke-width="2">
                            </circle>
                        </svg></span></button>
            </div>
            <div class="user_action">
                <div></div>
                <div>
                    <div v-if="isLogin" class="user_info">
                        <div class="CurrencyType"><span>{{ CurrencyType }}</span></div>
                        <div>
                            <div class="user_money">
                                <span style="margin-left: 4px;">{{ userInfo && userInfo.balanceAmtAccount ?
                                    userInfo.balanceAmtAccount.useBalance.toFixed(1) : "0.0" }}</span>
                            </div>
                            <div class="user_account">
                                <span style="margin-left: 4px;">{{ userInfo && userInfo.bonusAmtAccount ?
                                    userInfo.bonusAmtAccount.useBalance.toFixed(1) : "0.0" }}</span>
                            </div>
                        </div>
                        <div class="deposit deposit_app light_btn" @click.stop="toDeposit">+</div>
                        <div class="deposit deposit_PC light_btn" @click.stop="toDeposit">{{ $t("deposit.Deposit") }}</div>
                        <div class="deposit withdraw_PC" @click.stop="toWithdraw">{{ $t("Withdraw.Withdraw") }}</div>
                    </div>
                    <div v-else class="user_login">
                        <div class="login" @click="toLogin(2)">{{ $t("login.SignIn") }}</div>
                        <div class="sign_up light_btn" @click="toLogin(1)">
                            <i class="iconfont icon-a-liwujiangpin" :class="{ 'sidebarOpened': sidebar.opened }"></i>
                            <span>{{ $t("login.SignUp") }}</span>
                        </div>
                    </div>
                    <div v-if="isLogin" class="Notification" :class="{ 'act': isShowNotification }"
                        @click.stop="toggleNotification">
                        <i class="iconfont icon-xiangling"></i>
                    </div>
                    <div v-if="isLogin" class="person" @click.stop="isShowPersonList = true">
                        <img class="avatar" src="@/assets/person/avatar.png" />
                        <div class="vip">
                            <i class="iconfont icon-liubianxing"></i>
                            <div class="vip_num">{{ userInfo.levelDetailVo ? (userInfo.levelDetailVo.memberLevelCode ||
                                0) :
                                0 }}</div>
                        </div>
                        <div v-if="isShowPersonList" class="persom-mask" @click.stop="isShowPersonList = false"></div>
                        <div v-if="isShowPersonList" class="person-list">
                            <div class="menus">
                                <div class="menu-item" @click="toMyProfile">
                                    <div class="icon">
                                        <img class="img" src="@/assets/person/icon_Profile.png" />
                                        <img class="imgAct" src="@/assets/person/icon_ProfileAct.png" />
                                    </div>
                                    <div>{{ $t("MyProfile.Profile") }}</div>
                                </div>
                                <div class="menu-item" @click="toBetHistory">
                                    <div class="icon">
                                        <img class="img" src="@/assets/person/icon_BetsHistory.png" />
                                        <img class="imgAct" src="@/assets/person/icon_BetsHistoryAct.png" />
                                    </div>
                                    <div>{{ $t("BetsHistory.BetsHistory") }}</div>
                                </div>
                                <div class="menu-item" @click="toWithdraw">
                                    <div class="icon">
                                        <img class="img" src="@/assets/person/icon_Withdraw.png" />
                                        <img class="imgAct" src="@/assets/person/icon_WithdrawAct.png" />
                                    </div>
                                    <div>{{ $t("Withdraw.Withdraw") }}</div>
                                </div>
                                <div class="menu-item" @click="toTransaction">
                                    <div class="icon">
                                        <img class="img" src="@/assets/person/icon_Transaction.png" />
                                        <img class="imgAct" src="@/assets/person/icon_TransactionAct.png" />
                                    </div>
                                    <div>{{ $t("Transaction.Transaction") }}</div>
                                </div>
                                <div class="menu-item" :class="{ 'act': pathAct == 'wallet' }"
                                    @click="onMenuItem('wallet')">
                                    <div class="icon">
                                        <img class="img" src="@/assets/person/icon_Wallet.png" />
                                        <img class="imgAct" src="@/assets/person/icon_WalletAct.png" />
                                    </div>
                                    <div>{{ $t("Wallet.Wallet") }}</div>
                                </div>
                                <div class="menu-item" @click="$refs.BonusTransferPopup.open()">
                                    <div class="icon">
                                        <!-- <img class="img" src="@/assets/person/icon_Transfer.png" />
                                        <img class="imgAct" src="@/assets/person/icon_TransferAct.png" /> -->
                                        <!-- <i class="iconfont icon-055 img"></i> -->
                                        <i class="iconfont icon-055 imgAct"></i>
                                    </div>
                                    <div>{{ $t("person.BonusTransfer") }}</div>
                                </div>
                                <div class="menu-item" @click="onShowDownAppPopup">
                                    <div class="icon">
                                        <img class="img" src="@/assets/person/icon_Install.png" />
                                        <img class="imgAct" src="@/assets/person/icon_InstallAct.png" />
                                    </div>
                                    <div>{{ $t("person.Install") }}</div>
                                </div>
                                <div class="menu-item" @click="toSetting">
                                    <div class="icon">
                                        <img class="img" src="@/assets/person/icon_Setting.png" />
                                        <img class="imgAct" src="@/assets/person/icon_SettingAct.png" />
                                    </div>
                                    <div>{{ $t("Setting.Setting") }}</div>
                                </div>
                            </div>
                            <div class="logout menu-item" @click="handleLogout">
                                <div class="icon">
                                    <img class="img" src="@/assets/person/icon_SignOut.png" />
                                    <img class="imgAct" src="@/assets/person/icon_SignOutAct.png" />
                                </div>
                                <div>{{ $t("logout.SignOut") }}</div>
                            </div>
                        </div>
                    </div>
                    <language :show="true" :showIcon="true"></language>
                </div>
            </div>
        </div>
        <logoutPopup ref="logoutPopup" @logout="refresh"></logoutPopup>
        <BonusTransferPopup ref="BonusTransferPopup" @confirm="isLogin && getUserInfo()"></BonusTransferPopup>
    </div>
</template>

<script>
import statusBar from '@/components/statusBar'
import language from '@/components/language'
import logoutPopup from '@/components/logoutPopup'
import BonusTransferPopup from '@/components/BonusTransferPopup'
import { mapState } from 'vuex'
import { getUserInfo } from '@/api/user'
import { isPWA, getPlatform } from '@/utils'
import { Toast } from 'vant'
export default {
    name: 'Header',
    components: { statusBar, language, logoutPopup, BonusTransferPopup },
    data() {
        return {
            isShowPersonList: false,
            pathAct: ''
        }
    },
    computed: {
        ...mapState({
            isShowNotification: state => state.app.isShowNotification,
            device: state => state.app.device,
            sidebar: state => state.app.sidebar
        }),
        isLogin() {
            return !!this.$store.getters.token
        },
        logoUrl() {
            return this.$store.getters.sysConfig?.homeLogoUrl
        },
        logoUrl2() {
            return this.$store.getters.sysConfig?.homeImageUrl
        },
        userInfo() {
            return this.$store.getters.userInfo
        },
        isShowDownAppNav() {
            const isShowDownAppNav = Number(this.$store.state.system.isShowDownAppNav)
            // let downType = this.$store.state.vuex_sysConfig && this.$store.state.vuex_sysConfig.downType || 'apk'
            return isShowDownAppNav === 1 && (!isPWA()) && getPlatform() !== 'android' && getPlatform() !== 'Android' && getPlatform() !== 'ios'
        }
    },
    created() {
        this.pathAct = this.$route.name
        if (this.isLogin) getUserInfo()
    },
    methods: {
        onMenuItem(act) {
            if (this.pathAct === act) {
                Toast(this.$t('lang.CurrentlyPage'))
                return
            }
            this.pathAct = act
            this.onNavTo({ url: act, type: 'post' })
        },
        toggleSideBar() {
            this.$store.dispatch('app/toggleSideBar')
        },
        toggleNotification() {
            this.$store.dispatch('app/toggleNotification')
            this.$forceUpdate()
        },
        toLogin(type) {
            this.$store.dispatch('user/changeLoginType', type)
            this.$store.dispatch('user/setIsShowLoginPopup', true)
        },
        handleLogout() {
            this.$refs.logoutPopup.open()
        },
        refresh() {
            if (this.$route.path.indexOf('/Home') !== -1) {
                const opt = this.$route?.query
                if (opt && opt.token) {
                    this.onNavTo({ url: '/Home', replace: 1 })
                } else {
                    window.location.reload()
                }
            } else {
                this.onNavTo({ url: '/Home', replace: 1 })
            }
        },
        toSearch() {
            this.$store.dispatch('user/setIsShowSearchPopup', true)
        },
        toDeposit() {
            this.$store.dispatch('user/setIsShowDepositPopup', true)
        },
        toWithdraw() {
            this.$store.dispatch('user/setIsShowWithdrawPopup', true)
        },
        toMyProfile() {
            this.$store.dispatch('user/setIsShowMyProfilePopup', true)
        },
        toSetting() {
            this.$store.dispatch('user/setIsShowSettingPopup', true)
        },
        toBetHistory() {
            this.$store.dispatch('user/setIsShowBetHistoryPopup', true)
        },
        toTransaction() {
            this.$store.dispatch('user/setIsShowTransactionPopup', true)
        },
        onShowDownAppPopup() {
            this.$store.dispatch('user/setIsShowDownAppPopup', true)
        },
        onCloseDownAppNav() {
            this.$store.dispatch('system/setIsShowDownAppNav', 2)
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.fixed-header {
    width: 100%;
    // position: relative;
    // z-index: 98;
    box-shadow: 0 4px 20px #0006;
    background: $bg-navbar-color;
    border-bottom: 1px solid #7E848B;

    .downApp {
        background: rgb(255, 120, 45);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 .2rem;
        box-sizing: border-box;
        height: .9rem;
        position: relative;

        &>div.icon-close {
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, .3);
            border-bottom-right-radius: 100%;
            width: .53rem;
            height: .45rem;
            padding-right: .08rem;
            padding-bottom: .1rem;
            box-sizing: border-box;

            &>i {
                font-size: .2rem;
                font-weight: bold;
                color: rgba(255, 255, 255, .8);
            }
        }

        &>div.tips {
            padding-left: .28rem;
            color: #fff;
            font-size: .28rem;

            .money {
                color: #f3df00;
                font-size: .3rem;
                font-weight: 700;
            }
        }

        &>div.btn {
            background: #fff;
            color: #ec0048;
            font-size: .18rem;
            line-height: .5rem;
            padding: .048rem .16rem;
            padding-right: .2rem;
            border-radius: .2rem;
            display: flex;
            align-items: center;

            &>i {
                margin-right: .08rem;
            }
        }
    }

    .pages-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        padding-right: .2rem;
        height: 1rem;
        padding-right: .2rem;
        box-sizing: border-box;
        position: relative;
        padding-left: 260px;
        z-index: 99;

        &>div:first-child {
            display: flex;
            align-items: center;
            padding-left: .2rem;
            position: absolute;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 3;

            &>i {
                color: #fff;
                font-size: .48rem;
                margin-right: .14rem;
                transition: all .3s;

                &.sidebarOpened {
                    transform: rotate(180deg);
                }
            }

            .logo {
                height: .48rem;
                width: auto;
                will-change: auto !important;
                cursor: pointer;
            }

            .MuiButtonBase-root {
                height: 40px;
                width: 100%;
                max-width: 180px;
                border-radius: 16px;
                border: 2px solid $border-input-color;
                background: $bg-input-color;
                color: $font-placeholder-color;
                text-transform: capitalize;
                white-space: nowrap;
                place-content: center space-between;
                -webkit-box-align: center;
                align-items: center;
                display: flex;
                flex-flow: row;
                -webkit-box-pack: justify;
                position: relative;
                box-sizing: border-box;
                -webkit-tap-highlight-color: transparent;
                cursor: pointer;
                user-select: none;
                vertical-align: middle;
                appearance: none;
                font-family: __Rubik_7a808d, __Rubik_Fallback_7a808d;
                font-weight: 500;
                font-size: 0.26rem;
                line-height: 1.75;
                min-width: 64px;
                box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
                outline: 0px;
                margin: 0px;
                margin-left: .1rem;
                text-decoration: none;
                padding: 6px 16px;
                transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1);

                .MuiButton-icon {
                    display: flex;
                    align-items: center;
                    vertical-align: bottom;
                    margin-left: .05rem;
                }
            }
        }

        .user_action {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            z-index: 2;
            margin: 0 auto;
            max-width: 1414px;
            min-width: 720px;
            padding-left: 32px;
            padding-right: 32px;
            width: 100%;

            &>div:last-child {
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }

            .user_info {
                display: flex;
                align-items: center;
                // column-gap: 10px;
                font-size: .24rem;
                background: $bg-input-color;
                padding: .01rem .15rem;
                padding-right: .08rem;
                border-radius: .1rem;
                border: .01rem solid $border-color;

                .CurrencyType {
                    background: $font-theme-color;
                    padding: .02rem;
                    border-radius: 50%;
                    flex-shrink: 0;
                    width: .36rem;
                    height: .36rem;
                    text-align: center;
                    line-height: .36rem;
                    color: #fff;
                    font-size: .23rem;
                }

                .user_account,
                .user_money {
                    // display: flex;
                    // align-items: center;
                    color: #FFFFFF;
                    // background: rgba(255, 255, 255, 0.15);
                    padding: 0 .08rem;
                    border-radius: .2rem;
                }

                .user_account {
                    color: rgba(255, 255, 255, .8);
                }

                .deposit {
                    height: .6rem;
                    border-radius: .17rem;
                    // background: #fff;
                    // color: #c71800;
                    line-height: .6rem;
                    font-size: .5rem;
                    margin-left: .1rem;
                    cursor: pointer;

                    &.deposit_app {
                        display: none;
                        width: .6rem;
                        padding: 0;
                        text-align: center;
                        line-height: .55rem;
                        padding-right: .02rem;
                    }

                    &.deposit_PC {
                        font-size: .25rem;
                        font-weight: bold;
                        padding: 0 .3rem;
                        text-transform: uppercase;
                    }

                    &.withdraw_PC {
                        font-size: .25rem;
                        font-weight: bold;
                        padding: 0 .3rem;
                        text-transform: uppercase;
                        background: $bg-btn-color;
                        color: $font-btn-color;
                        margin-left: .1rem;
                    }
                }
            }

            .Notification {
                margin-left: .2rem;
                width: .7rem;
                height: .7rem;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border-radius: .12rem;

                &:hover {
                    background: $bg-input-color;
                    border: .01rem solid $border-color;

                    &>i {
                        color: #c1c1c1 !important;
                    }
                }

                &>i {
                    font-size: .38rem;
                    color: #9FA6B0;
                }

                &.act {
                    background: $bg-input-color;
                    border: .01rem solid $border-color;

                    &>i {
                        color: #c1c1c1 !important;
                    }
                }
            }

            .person {
                position: relative;

                .avatar {
                    width: .6rem;
                    height: .6rem;
                    border-radius: 50%;
                    margin: 0 .2rem;
                    cursor: pointer;
                }

                .vip {
                    width: .38rem;
                    height: .38rem;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    cursor: pointer;

                    &>i.iconfont {
                        color: #9FA6B0;
                        font-size: .38rem;
                        vertical-align: middle;
                    }

                    .vip_num {
                        width: .38rem;
                        height: .38rem;
                        position: absolute;
                        color: #fff;
                        font-size: .25rem;
                        line-height: .38rem;
                        text-align: center;
                        top: .1rem;
                        left: 0;
                    }
                }

                .persom-mask {
                    position: fixed;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    z-index: 9998;
                }

                .person-list {
                    position: absolute;
                    width: 220px;
                    border-radius: 12px;
                    box-shadow: 0 4px 20px #0006;
                    padding-top: 10px;
                    right: 0;
                    background: $bg-popup-color;
                    bottom: -.28rem;
                    transform: translateY(100%);
                    overflow: hidden;
                    z-index: 999999999;

                    .menu-item {
                        width: 100%;
                        // background: #1d2027;
                        color: #C1C1C1;
                        height: 46px;
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                        padding: 0 13px 0 20px;
                        cursor: pointer;

                        &.logout {
                            height: 66px;
                            border-top: .01rem solid $border-color;
                        }

                        .icon {
                            margin-right: 6px;

                            &>img {
                                width: 20px;
                                height: 20px;
                                vertical-align: middle;

                                &.imgAct {
                                    display: none;
                                }
                            }

                            &>i.iconfont {
                                width: 20px;
                                height: 20px;
                                vertical-align: middle;
                                display: inline-block;
                                font-size: .35rem;
                                text-align: center;
                                line-height: 20px;
                                color: #dbdbdb;
                                margin-bottom: 2px;
                            }

                            &.vip {
                                padding: 7px;

                                &>img {
                                    width: 19px;
                                    height: 19px;
                                }
                            }
                        }

                        &.act,
                        &:hover {
                            color: #fff;
                            font-weight: bold;
                            background: $bg-tab-act-color;

                            .icon {
                                &>img {
                                    &.imgAct {
                                        display: block;
                                    }

                                    &.img {
                                        display: none;
                                    }
                                }

                                &>i.iconfont {
                                    color: #fff;
                                    font-weight: 400;
                                }
                            }

                            &.arrow {
                                &::after {
                                    border-color: #fff;
                                }
                            }
                        }

                        &.arrow {
                            position: relative;

                            &::after {
                                content: "";
                                position: absolute;
                                width: 10px;
                                height: 10px;
                                border-radius: 1px;
                                border-top: 2px solid;
                                border-right: 2px solid;
                                border-color: #516382;
                                right: 16px;
                                top: 18px;
                                transform: rotate(45deg);
                                transition-duration: 0.3s;
                            }

                            &.botArrow {
                                &::after {
                                    transform: rotate(135deg);
                                }
                            }

                            &.topArrow {
                                border-radius: 12px 12px 0 0;

                                &::after {
                                    top: 22px;
                                    transform: rotate(-45deg);
                                }
                            }
                        }
                    }
                }
            }

            .user_login {
                font-size: .26rem;
                height: .7rem;
                line-height: .7rem;
                display: flex;
                align-items: center;

                .login {
                    color: #fff;
                    cursor: pointer;
                    text-align: center;
                    padding: 0 .26rem;
                    border-radius: .94rem;
                    margin-right: .1rem;
                    transition: background 0.3s;
                    background: #363639;
                    margin-right: .12rem;

                    &:hover {
                        color: $font-btn-color;
                        background: $bg-btn-color;
                    }
                }

                .sign_up {}
            }

            .light_btn {
                text-align: center;
                box-sizing: border-box;
                font-size: .28rem;
                font-style: italic;
                transition: 0.6s;
                -webkit-animation: light 3s infinite ease-in-out forwards;
                animation: light 3s infinite ease-in-out forwards;
                -webkit-filter: drop-shadow(0px 0px 5px #FFB000);
                filter: drop-shadow(0px 0px 5px #FFB000);
                width: max-content;
                height: .64rem;
                line-height: .63rem;
                display: flex;
                align-items: center;
                background: rgba(255, 120, 45, 0.32);
                border: .04rem solid #FFB000;
                border-radius: .94rem;
                padding: .12rem .26rem;
                position: relative;
                overflow: hidden;
                cursor: pointer;
                color: #fff;
                font-style: italic;

                &::after {
                    content: "";
                    position: absolute;
                    width: 2.84rem;
                    height: 100%;
                    top: 0;
                    left: -2.84rem;
                    background: -webkit-linear-gradient(-40deg, #ffffff00 0%, #ffffff00 25%, #ffffff 25.2%, #fff 46%, #ffffff00 46.2%, #ffffff00 49%, #ffffff 49.2%, #fff 53.5%, #ffffff00 53.7%, #ffffff00 100%);
                    -webkit-animation: move 3s infinite ease-in-out forwards;
                    animation: move 3s infinite ease-in-out forwards;
                    -webkit-filter: drop-shadow(0px 0px 5px #fff);
                    filter: drop-shadow(0px 0px 5px #fff);
                }

                &>i {
                    margin-right: 6px;
                }
            }

            @keyframes light {
                50% {
                    -webkit-filter: drop-shadow(0px 0px 0px #FF782D00);
                    filter: drop-shadow(0px 0px 0px #FF782D00);
                }

                100% {
                    -webkit-filter: drop-shadow(0px 0px 5px #FF782D);
                    filter: drop-shadow(0px 0px 5px #FF782D);
                }
            }

            @keyframes move {
                0% {
                    left: -2.82rem;
                }

                75% {
                    left: -2.84rem;
                }

                100% {
                    left: 100%;
                }
            }
        }
    }
}

html[data-device-nodesktop="1"] {
    .fixed-header {
        z-index: 100;

        .downApp {
            display: none;
        }
    }
}
</style>
