<template>
    <div class="app-main" id="AppMain">
        <div class="app-main-wrap">
            <transition name="fade-transform" mode="out-in">
                <router-view :key="key" />
            </transition>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Footer from './Footer.vue'
export default {
    name: 'AppMain',
    components: { Footer },
    computed: {
        key() {
            return this.$route.path
        }
    }
}
</script>

<style lang="scss" scoped>
.app-main {
    overflow: auto;
    padding-bottom: 1px;
    .app-main-wrap {
        margin: 0 auto;
        max-width: 1414px;
        min-width: 720px;
        padding-left: 32px;
        padding-right: 32px;
        width: 100%;
    }
}

html[data-device-nodesktop="1"] {
    .app-main {
        max-height: 100vh;
    }
}
html[data-device-nodesktop="0"] {
    .app-main {
        right: 0 !important;
    }
}
</style>
