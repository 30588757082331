<template>
    <div>
        <div class="MuiBox-root" @click="toggleSideBar"><svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                <circle cx="8" cy="8" r="7.5" fill="#FF782D" fill-opacity="0.32" stroke="#FF782D"></circle>
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.44599 5.04134C9.1106 4.59907 10 5.07554 10 5.87386L10 10.1261C10 10.9244 9.1106 11.4009 8.44599 10.9586L5.25103 8.83251C4.65637 8.43679 4.65637 7.56321 5.25102 7.16749L8.44599 5.04134Z"
                    fill="white"></path>
            </svg></div>
        <div class="Opend">
            <div class="logo" @click.stop="refresh">
                <img class="logo_img" :src="sysConfig ? sysConfig.homeLogoUrl : ''" />
                <div class="logo_txt">{{ sysConfig ? sysConfig.projectName : '' }}</div>
            </div>
            <!-- <div class="menu-item-wrap">
                <div class="menu-item" :class="{ 'act': pathAct == 'bonus-center' }" @click="onMenuItem('bonus-center')">
                    <div class="icon">
                        <img class="img" src="@/assets/sidebar/icon_bonus.png" />
                        <img class="imgAct" src="@/assets/sidebar/icon_bonusAct.png" />
                    </div>
                    <div>{{ $t("person.Bonus") }}</div>
                </div>
            </div> -->
            <!-- <div class="menus-flex">
                <div class="menu-item" @click="onMenuItem('vip')">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M6.85635 19C6.75058 19 6.6686 18.9686 6.61043 18.9057C6.55225 18.8429 6.51523 18.78 6.49936 18.7171L5.00793 13.2486C5.00264 13.2257 5 13.2057 5 13.1886C5 13.1371 5.01587 13.0943 5.0476 13.06C5.08462 13.02 5.12693 13 5.17453 13H6.65009C6.75587 13 6.83784 13.0314 6.89602 13.0943C6.9542 13.1571 6.99122 13.22 7.00708 13.2829L7.76073 16.4714L8.51438 13.2829C8.53025 13.22 8.56727 13.1571 8.62544 13.0943C8.68362 13.0314 8.76559 13 8.87137 13H10.3469C10.3945 13 10.4342 13.02 10.4659 13.06C10.503 13.0943 10.5215 13.1371 10.5215 13.1886C10.5215 13.2057 10.5188 13.2257 10.5135 13.2486L9.0221 18.7171C9.00623 18.78 8.96921 18.8429 8.91104 18.9057C8.85286 18.9686 8.77088 19 8.66511 19H6.85635Z"
                            fill="white"></path>
                        <path
                            d="M11.2877 19C11.2295 19 11.1793 18.9771 11.137 18.9314C11.0947 18.8857 11.0735 18.8314 11.0735 18.7686V13.2314C11.0735 13.1686 11.0947 13.1143 11.137 13.0686C11.1793 13.0229 11.2295 13 11.2877 13H12.9061C12.9642 13 13.0145 13.0229 13.0568 13.0686C13.0991 13.1143 13.1202 13.1686 13.1202 13.2314V18.7686C13.1202 18.8314 13.0991 18.8857 13.0568 18.9314C13.0145 18.9771 12.9642 19 12.9061 19H11.2877Z"
                            fill="white"></path>
                        <path
                            d="M14.2084 19C14.1502 19 14.1 18.9771 14.0577 18.9314C14.0153 18.8857 13.9942 18.8314 13.9942 18.7686V13.2314C13.9942 13.1686 14.0153 13.1143 14.0577 13.0686C14.1 13.0229 14.1502 13 14.2084 13H16.6121C17.0934 13 17.5112 13.0771 17.8656 13.2314C18.2252 13.38 18.5029 13.6114 18.6985 13.9257C18.8995 14.2343 19 14.6257 19 15.1C19 15.5743 18.8995 15.9629 18.6985 16.2657C18.5029 16.5629 18.2252 16.7829 17.8656 16.9257C17.5112 17.0629 17.0934 17.1314 16.6121 17.1314H16.0568V18.7686C16.0568 18.8314 16.0357 18.8857 15.9933 18.9314C15.951 18.9771 15.9008 19 15.8426 19H14.2084ZM16.0171 15.5457H16.5725C16.6571 15.5457 16.7391 15.5114 16.8184 15.4429C16.8977 15.3686 16.9374 15.2486 16.9374 15.0829C16.9374 14.9743 16.9109 14.8657 16.8581 14.7571C16.8105 14.6486 16.7153 14.5943 16.5725 14.5943H16.0171V15.5457Z"
                            fill="white"></path>
                        <path d="M21 4.5L18.5 11H17L18.5 7L16 8L12 5L8 8L5.5 7L7 11H5.5L3 4.5L7 6L12 2L17 6L21 4.5Z"
                            fill="#FF782D"></path>
                        <path opacity="0.5"
                            d="M16 11H8L7 8.49997L8.5 8.99997L11.9998 6.49997L15.5 8.99997L17 8.49997L16 11Z"
                            fill="#FF782D"></path>
                    </svg>
                    <div class="text">VIP</div>
                </div>
                <div class="menu-item" @click="onMenuItem('bonus-center')">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M12.2169 5.00793C12.3039 4.72326 12.4655 4.44714 12.6977 4.18349L12.6924 4.18045C12.2401 3.91708 11.7202 3.96713 11.2784 4.21682C11.4865 4.47716 11.6322 4.744 11.7124 5.01391C11.8806 4.99762 12.0486 4.99571 12.2169 5.00793Z"
                            fill="#C1C1C1"></path>
                        <path
                            d="M11.5293 7.96259L8.96465 9.44329L11.4176 10.848C11.7738 11.0511 12.205 11.0511 12.5425 10.848L14.9806 9.4741L12.3845 7.97525C12.1002 8.01253 11.8121 8.00861 11.5293 7.96259Z"
                            fill="#C1C1C1"></path>
                        <path
                            d="M8 10.3112V13.4912L5.01167 11.777L5.01926 9.47212C5.01926 9.10901 5.44965 8.88867 5.7531 9.06877L8 10.3112Z"
                            fill="#C1C1C1"></path>
                        <path
                            d="M16 13.4596L18.9928 11.7193L19 9.54577C19 9.19953 18.5827 8.95451 18.2662 9.14233L16 10.3651V13.4596Z"
                            fill="#C1C1C1"></path>
                        <path
                            d="M7.96055 8.8683L8.98402 8.2774C7.95351 8.33978 7.41564 7.36785 6.7069 6.80005L6.24375 7.06176C5.91001 7.22603 5.94857 7.6798 6.22493 7.87441L7.96055 8.8683Z"
                            fill="#C1C1C1"></path>
                        <path
                            d="M15.0367 8.35179L15.9928 8.90376L17.7539 7.91138C18.0632 7.75916 18.0919 7.25596 17.7727 7.0986L17.3473 6.85429C16.5753 7.32255 15.9848 8.25032 15.0367 8.35179Z"
                            fill="#C1C1C1"></path>
                        <path
                            d="M8 14.6777L5.00784 12.9404L5 15.3205C5 15.7971 5.27034 16.2372 5.69517 16.4755L8 17.7639V14.6777Z"
                            fill="#C1C1C1"></path>
                        <path
                            d="M18.989 12.8853L16 14.6451V17.7479L18.3242 16.4393C18.7298 16.2193 18.9807 15.816 18.9807 15.3759L18.989 12.8853Z"
                            fill="#C1C1C1"></path>
                        <path
                            d="M12.5037 16.4697L15 15V18.0771L12.4889 19.4909C12.1853 19.6619 11.8146 19.6625 11.5104 19.4924L9 18.0892V15.0246L11.4942 16.4728C11.8066 16.6542 12.1925 16.653 12.5037 16.4697Z"
                            fill="#C1C1C1"></path>
                        <path
                            d="M9 10.8643L11.5221 12.2589C11.82 12.4236 12.1812 12.4255 12.4808 12.2638L15 10.9046V14.0412L12.4992 15.4954C12.1902 15.6751 11.8089 15.6762 11.4989 15.4984L9 14.0649V10.8643Z"
                            fill="#C1C1C1"></path>
                        <g opacity="0.5">
                            <path
                                d="M13 6.5C13 6.77614 12.5523 7 12 7C11.4477 7 11 6.77614 11 6.5C11 6.22386 11.4477 6 12 6C12.5523 6 13 6.22386 13 6.5Z"
                                fill="#C1C1C1"></path>
                            <path
                                d="M8.24895 5.80466C8.76107 5.52233 9.72922 5.36783 10.3775 5.59153C10.5737 5.39003 10.8201 5.24505 11.0837 5.15095C9.94221 3.47376 6.76097 2.88337 5.20233 4.32647C4.3672 5.0997 6.32315 5.14694 8.07011 6.54436C8.8842 7.40557 9.71529 7.52374 10.3072 7.33132C10.1354 7.12831 10 6.85513 10 6.5001L10.0001 6.48827C9.10934 5.63804 8.24895 5.80466 8.24895 5.80466Z"
                                fill="#C1C1C1"></path>
                            <path
                                d="M13.5018 5.47979C13.305 5.31504 13.074 5.19857 12.8298 5.12201C14.0567 3.4932 17.2911 3.11743 18.732 4.68609C19.4965 5.51836 17.5631 5.42154 15.7321 6.68608C14.9565 7.3985 14.2131 7.5205 13.6575 7.37145C13.9173 7.08609 14.0402 6.70205 13.9879 6.31977C14.7578 5.69748 15.4563 5.66154 15.4563 5.66154C15.0234 5.33541 14.1365 5.21261 13.5018 5.47979Z"
                                fill="#C1C1C1"></path>
                        </g>
                    </svg>
                    <div class="text">{{ $t("person.Bonus") }}</div>
                </div>
            </div> -->
            <div class="game_classify">
                <!-- <div class="game_classify_top">
                    <div :class="{ 'act': act == 'Popular' }" @click="onClassifyTop('Popular')">
                        <i class="iconfont icon-dianzanmw"></i>
                    </div>
                    <div :class="{ 'act': act == 'Favoritos', 'disabled': !isLogin }"
                        @click="isLogin && onClassifyTop('Favoritos')">
                        <i class="iconfont icon-zan"></i>
                    </div>
                    <div :class="{ 'act': act == 'Recente', 'disabled': !isLogin }"
                        @click="isLogin && onClassifyTop('Recente')">
                        <i class="iconfont icon-shijian"></i>
                    </div>
                </div> -->
                <div class="game_classify_title">{{ $t("person.Play") }}</div>
                <div class="menu-item-wrap" v-for="(item, index) in gameClassify" :key="index">
                    <div class="menu-item"
                        :class="{ 'act': act == item.gameCategoryType || hoverAct == item.gameCategoryType || item.isShowSub }"
                        @click.stop="onArrow(item)">
                        <div class="icon">
                            <img class="img" :src="item.unActivationImg" />
                            <img class="imgAct" :src="item.activationImg" />
                        </div>
                        <div>{{ item.categoryShowName }}</div>
                        <div v-if="item.list && item.list.length" class="arrow iconfont icon-open"
                            :class="{ 'botArrow': !item.isShowSub, 'topArrow': item.isShowSub }"></div>
                    </div>
                    <div v-if="item.isShowSub && item.list && item.list.length" class="mobile-sub-menus">
                        <div class="sub-menus-item" v-for="(citem, cindex) in item.list" :key="cindex"
                            @click.stop="toCasino(item, citem)">
                            <van-image width="0.38rem" height="0.3rem" fit="contain" :src="citem.iconImgUrl" />
                            <span>{{ citem.gamePlatformType }}</span>
                            <!-- <van-image width="2.08rem" fit="contain" :src="citem.iconImgUrl" /> -->
                            <!-- <van-image height="0.7rem" fit="contain" :src="citem.iconImgUrl" /> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="game_classify">
                <div class="game_classify_title">{{ $t("person.Earn") }}</div>
                <div class="menu-item-wrap">
                    <div class="menu-item" :class="{ 'act': pathAct == 'Affiliate' }" @click="onMenuItem('Affiliate')">
                        <div class="icon">
                            <img class="img" src="@/assets/sidebar/icon_share.png" />
                            <img class="imgAct" src="@/assets/sidebar/icon_shareAct.png" />
                        </div>
                        <div style="font-weight: bold;">{{ $t("tabbar.Affiliate") }}</div>
                    </div>
                </div>
            </div>
            <div class="menu-item-wrap" style="margin-bottom: .08rem;">
                <div class="menu-item" :class="{ 'act': pathAct == 'vip' }" @click="onMenuItem('vip')">
                    <div class="icon">
                        <img class="img" src="@/assets/sidebar/icon_Vip.png" />
                        <img class="imgAct" src="@/assets/sidebar/icon_VIPAct.png" />
                    </div>
                    <div class="text" style="font-weight: bold;">VIP</div>
                </div>
            </div>
            <div class="menu-item-wrap" style="margin-bottom: .08rem;">
                <div class="menu-item" :class="{ 'act': pathAct == 'Event' }" @click="onMenuItem('Event')">
                    <div class="icon">
                        <img class="img" src="@/assets/sidebar/icon_Promotion.png" />
                        <img class="imgAct" src="@/assets/sidebar/icon_PromotionAct.png" />
                    </div>
                    <div style="font-weight: bold;">{{ $t("person.Promotion") }}</div>
                </div>
            </div>
            <div class="sidebar_footer">
                <div class="language" @click="$refs.lang.onShowLangPopup()">
                    <language ref="lang" :show="true" :showIcon="true" :isUp="true" contentRight="-2rem"></language>
                    <div class="nowLang">{{ langTypeName }}</div>
                    <div class="arrow"></div>
                </div>
                <div class="mui-1uvz28q">
                    <div v-if="sysConfig && sysConfig.telegram" @click="onNavTo({ url: sysConfig.telegram, type: 'href' })">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M16.9053 7.22398C17.0053 7.22198 17.2263 7.24698 17.3703 7.36398C17.466 7.44708 17.527 7.56306 17.5413 7.68899C17.5573 7.78199 17.5773 7.99498 17.5613 8.16098C17.3813 10.059 16.5993 14.663 16.2013 16.788C16.0333 17.688 15.7023 17.989 15.3813 18.018C14.6853 18.083 14.1563 17.558 13.4813 17.116C12.4253 16.423 11.8283 15.992 10.8033 15.316C9.61831 14.536 10.3863 14.106 11.0613 13.406C11.2383 13.222 14.3083 10.429 14.3683 10.176C14.3753 10.144 14.3823 10.026 14.3123 9.96398C14.2423 9.90198 14.1383 9.92298 14.0633 9.93998C13.9573 9.96398 12.2703 11.08 9.00231 13.285C8.52231 13.615 8.08931 13.775 7.70031 13.765C7.27231 13.757 6.44831 13.524 5.83531 13.325C5.08331 13.08 4.48631 12.951 4.53831 12.536C4.56531 12.32 4.86331 12.099 5.43131 11.873C8.92931 10.349 11.2613 9.34399 12.4293 8.85898C15.7613 7.47298 16.4543 7.23198 16.9053 7.22398Z"
                                fill="#C1C1C1">
                            </path>
                        </svg>
                    </div>
                    <div v-if="sysConfig && sysConfig.twitter" @click="onNavTo({ url: sysConfig.twitter, type: 'href' })">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M5.03413 6L10.4394 12.6187L5 18H6.22419L10.9864 13.2886L14.834 18H19L13.2906 11.009L18.3536 6H17.1294L12.7437 10.3391L9.20008 6H5.03413ZM6.83439 6.8258H8.74824L17.1995 17.1741H15.2856L6.83439 6.8258Z"
                                fill="#C1C1C1">
                            </path>
                        </svg>
                    </div>
                    <div v-if="sysConfig && sysConfig.onlineService"
                        @click="onNavTo({ url: sysConfig.onlineService, type: 'href' })">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M5.00445 10.875C5.00445 0.778957 20 0.778957 20 10.875L19.9956 15.15H19.9978C19.9978 18.0028 17.7525 20.325 14.8987 20.325C14.6765 20.7286 14.2611 21 13.7854 21H11.6431C10.9333 21 10.3578 20.3956 10.3578 19.65V18.75C10.3578 18.0045 10.9333 17.4 11.6431 17.4H13.7854C14.2611 17.4 14.6765 17.6715 14.8987 18.075C16.0249 18.075 17.03 17.5316 17.5419 16.5H16.568C15.8581 16.5 15.2827 15.8956 15.2827 15.15V11.55C15.2827 10.8045 15.8581 10.2 16.568 10.2H17.3878C16.6689 4.40319 8.33559 4.40319 7.61665 10.2H8.42756C9.13743 10.2 9.71289 10.8045 9.71289 11.55V15.15C9.71289 15.8956 9.13742 16.5 8.42756 16.5H6.28533C5.57546 16.5 5 15.8956 5 15.15C5 13.725 5.00445 12.3 5.00445 10.875Z"
                                fill="#C1C1C1"></path>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="PC-hide">
            <div class="logo" @click.stop="refresh">
                <img class="logo_img" :src="sysConfig ? sysConfig.homeLogoUrl : ''" />
            </div>
            <!-- <div class="icon-item" @click="onMenuItem('bonus-center')">
                <img class="img" src="@/assets/sidebar/icon_bonus.png" />
                <img class="imgAct" src="@/assets/sidebar/icon_bonusAct.png" />
                <div v-if="true" class="tips">{{ $t("person.Bonus") }}</div>
            </div> -->
            <!-- <div class="icon-item" @click="onMenuItem('Affiliate')">
                <img src="@/assets/person/inviteSubordinates.png" />
                <div v-if="true" class="tips">{{ $t("person.invite") }} {{ CurrencyType }}1,000</div>
            </div>
            <div class="icon-item" @click="toBonusRainPopup">
                <img src="@/assets/person/redpacket.png" />
                <div v-if="true" class="tips">{{ $t("person.redPacket") }}</div>
            </div> -->
            <div class="game_classify">
                <div class="icon-item"
                    :class="{ 'arrow': (item.list && item.list.length), 'act': act == item.gameCategoryType || hoverAct == item.gameCategoryType }"
                    v-for="(item, index) in gameClassify" :key="index" @mouseenter="enterMenuItem(item)"
                    @mouseleave="leaveMenuItem(item)" @click.stop="toCasino(item)">
                    <img class="img" :src="item.unActivationImg" />
                    <img class="imgAct" :src="item.activationImg" />
                    <div v-if="false" class="tips">{{ item.categoryShowName }}</div>
                </div>
            </div>
            <div class="icon-item" @click="onMenuItem('Affiliate')">
                <img class="img" src="@/assets/sidebar/icon_share.png" />
                <img class="imgAct" src="@/assets/sidebar/icon_shareAct.png" />
                <div v-if="true" class="tips">{{ $t("tabbar.Affiliate") }}</div>
            </div>
            <div class="icon-item" @click="onMenuItem('Event')">
                <img class="img" src="@/assets/sidebar/icon_Promotion.png" />
                <img class="imgAct" src="@/assets/sidebar/icon_PromotionAct.png" />
                <div v-if="true" class="tips">{{ $t("person.Promotion") }}</div>
            </div>
            <div class="icon-item" @click="onMenuItem('vip')">
                <img class="img" src="@/assets/sidebar/icon_Vip.png" />
                <img class="imgAct" src="@/assets/sidebar/icon_VIPAct.png" />
                <div v-if="true" class="tips">{{ $t("person.VIPClub") }}</div>
            </div>
            <!-- <div class="icon-item" @click="onShowDownAppPopup">
                <img class="img" src="@/assets/sidebar/icon_Install.png" />
                <img class="imgAct" src="@/assets/sidebar/icon_InstallAct.png" />
                <div v-if="true" class="tips">{{ $t("person.Install") }}</div>
            </div> -->
            <div class="icon-item" @click="onNavTo({ url: sysConfig.telegram, type: 'href' })">
                <!-- <img class="img" src="@/assets/sidebar/icon_bonus.png" />
                <img class="imgAct" src="@/assets/sidebar/icon_bonusAct.png" /> -->
                <i class="iconfont icon-kefuE"></i>
                <div v-if="true" class="tips">{{ $t("person.LiveChat") }}</div>
            </div>
        </div>
        <div class="sub-menus" :class="{ 'hover': isHover }" @mouseenter="enterMenuItem('sub')"
            @mouseleave="leaveMenuItem('sub')">
            <div class="sub-menus-item" :class="{ 'act': subAct == item.id }" v-for="(item, index) in PCSubMenus.list"
                :key="index" @click="toCasino(PCSubMenus, item)">
                <van-image width="0.38rem" height="0.3rem" fit="contain" :src="item.iconImgUrl" />
                <span>{{ item.gamePlatformType }}</span>
                <!-- <van-image height="0.7rem" fit="contain" :src="item.iconImgUrl" /> -->
                <!-- <span>{{ item.gamePlatformType }}</span> -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { getGameClassify } from '@/api/game'
import { getShareinfo } from '@/api/share'
import { Toast } from 'vant'
import language from '@/components/language'
export default {
    name: 'Sidebar',
    components: { language },
    data() {
        return {
            act: 0,
            hoverAct: 0,
            hoverActTemp: 0,
            isHover: false,
            subAct: 0,
            isShowSub: false,
            gameClassify: [],
            PCSubMenus: [],
            pathAct: '',
            shareData: {}
        }
    },
    computed: {
        ...mapState({
            sidebar: state => state.app.sidebar,
            device: state => state.app.device
        }),
        mobile() {
            return this.device === 'mobile'
        },
        isLogin() {
            return !!this.$store.getters.token
        },
        sysConfig() {
            return this.$store.getters.sysConfig
        },
        isOpened() {
            return !this.sidebar.opened
        }
    },
    watch: {
        $route: {
            handler: function (to, from) {
                this.act = ''
                this.subAct = ''
                const opt = this.$route?.params
                if (opt && opt.params) {
                    const params = JSON.parse(decodeURIComponent(opt?.params))
                    this.act = params?.code
                    if (params.gameTitleId) this.subAct = params.gameTitleId
                }
                this.pathAct = this.$route.name
            },
            deep: true
        },
        isOpened: {
            handler: function (newVal, oldVal) {
                if (!this.mobile && this.gameClassify.length) {
                    this.gameClassify.forEach(x => {
                        x.isShowSub = false
                    })
                }
            },
            deep: true
        }
    },
    created() {
        this.pathAct = this.$route.name
        this.getGameClassify()
        this.getShareInfo()
    },
    methods: {
        toggleSideBar() {
            this.$store.dispatch('app/toggleSideBar')
        },
        onClassifyTop(act) {
            const item = {
                gameCategoryType: act,
                categoryShowName: act
            }
            this.toCasino(item)
        },
        onMenuItem(act) {
            if (this.pathAct === act) {
                Toast(this.$t('lang.CurrentlyPage'))
                return
            }
            this.pathAct = act
            this.onNavTo({ url: act, type: 'post' })
        },
        enterMenuItem(item) {
            if (item !== 'sub') {
                this.PCSubMenus = item
                this.hoverAct = item.gameCategoryType
                this.hoverActTemp = item.gameCategoryType
            } else {
                this.hoverAct = this.hoverActTemp
            }
            if (!this.mobile) this.isHover = true
        },
        leaveMenuItem(item) {
            this.hoverAct = ''
            if (!this.mobile) this.isHover = false
        },
        getGameClassify() {
            getGameClassify().then(res => {
                const that = this
                if (res?.code) return
                const D = res?.result.filter(x => x.gameTitleIPage && x.gameTitleIPage.records && x.gameTitleIPage.records.length && x.gameCategoryType !== 'None')
                D.forEach(x => {
                    x.isLoading = 0
                    x.pageNum = 1
                    x.hasMore = true
                    x.list = []
                    x.isShowSub = false
                    if (x.gameTitleIPage && x.gameTitleIPage.records.length) {
                        x.classifyType = 'manufacturer' // 是厂商列表
                        x.list = x.gameTitleIPage.records
                        x.hasMore = x.gameTitleIPage.total < x.gameTitleIPage.records.length
                        x.total = x.gameTitleIPage.total
                    }
                })
                that.gameClassify = D
            })
        },
        onArrow(item) {
            item.isShowSub = !item.isShowSub
        },
        toCasino(item, citem) {
            if (this.act === item.gameCategoryType && !citem) {
                Toast(this.$t('lang.CurrentlyPage'))
                return
            }
            this.act = item.gameCategoryType
            const obj = {
                iconImg: item.activationImg,
                code: item.gameCategoryType,
                title: item.categoryShowName
            }
            if (this.act === item.gameCategoryType && citem && this.subAct === citem.id) {
                Toast(this.$t('lang.CurrentlyPage'))
                return
            }
            if (citem) {
                this.subAct = citem.id
                obj.gamePlatformType = citem.gamePlatformType
                obj.gameTitleId = citem.id
                obj.gameFactoryName = citem.gameFactoryName
            } else if (item.list && item.list.length) {
                const subItem = item.list[0]
                this.subAct = subItem.id
                obj.gamePlatformType = subItem.gamePlatformType
                obj.gameTitleId = subItem.id
                obj.gameFactoryName = subItem.gameFactoryName
            }
            const params = encodeURIComponent(JSON.stringify(obj))
            const timestamp = new Date().getTime()
            this.onNavTo({ url: 'Casino', type: 'post', params: { timestamp, params } })
        },
        toBonusRainPopup() {
            this.$store.dispatch('user/setIsShowRedPacketPopup', { show: true })
        },
        onShowDownAppPopup() {
            this.$store.dispatch('user/setIsShowDownAppPopup', true)
        },
        getShareInfo() {
            getShareinfo().then((res) => {
                if (res?.code) return
                const D = res.result
                this.shareData = D
            })
        },
        refresh() {
            if (this.$route.path.indexOf('/Home') !== -1) {
                const opt = this.$route?.query
                if (opt && opt.token) {
                    this.onNavTo({ url: '/Home', replace: 1 })
                } else {
                    window.location.reload()
                }
            } else {
                this.onNavTo({ url: '/Home', replace: 1 })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/common/styles/viriables.scss';

.MuiBox-root {
    place-content: center flex-end;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex-flow: row;
    -webkit-box-pack: end;
    position: fixed;
    top: 24px;
    left: 252px;
    cursor: pointer;
    transition: transform .28s;
    transform: rotate(0deg);
    background: rgb(27, 21, 42);
    border-radius: 50%;
    z-index: 101;
}

.Opend {
    display: block;
    height: 100%;
    box-sizing: border-box;
    padding: 18px 10px;
    padding-top: 8px;
    padding-right: 0;
    overflow: auto;
    width: 260px;
    position: relative;
    z-index: 2;
    opacity: 1;
    transition: display 3s;
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    // margin-top: .05rem;
    // margin-bottom: .1rem;
    cursor: pointer;

    .logo_img {
        height: 1.5rem;
        width: 1.5rem;
        vertical-align: middle;
        cursor: pointer;
    }

    .logo_txt {
        margin: 0px;
        font-family: __Rubik_7a808d, __Rubik_Fallback_7a808d;
        line-height: 1.57;
        font-style: italic;
        color: rgb(255, 255, 255);
        height: 30px;
        animation: 0.3s ease 0s 1 normal none running animation-yvxynm;
        font-size: 30px;
        font-weight: 600;
        user-select: none;
    }
}

.PC-hide {
    box-sizing: border-box;
    width: 64px;
    height: 100%;
    display: none;
    pointer-events: none;
    // transform: translateY(0);
    position: relative;
    z-index: 2;
    padding: 18px 0;

    .logo_img {
        height: .6rem;
        width: .6rem;
        vertical-align: middle;
    }

    .icon-item {
        height: 46px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 3px;
        cursor: pointer;
        position: relative;

        i.iconfont {
            font-size: .37rem;
            color: $font-sub-color;
            margin: 0 .14rem;
            display: block;

            &.icon-kefuE {
                color: $font-theme-color;
            }
        }

        .tips {
            display: none;
            position: absolute;
            right: -10px;
            height: 100%;
            transform: translateX(100%);
            border-radius: 12px;
            background: #6B6F74;
            width: auto;
            white-space: nowrap;
            color: #fff;
            line-height: 46px;
            padding: 0 10px;

            &::before {
                border: 8px solid transparent;
                -moz-border-radius: 10px;
                border-radius: 10px;
                border-right-color: #6B6F74;
                content: "";
                left: -14px;
                position: absolute;
                top: 15px;
                z-index: -1;
            }
        }

        &>img {
            width: 33px;
            height: 33px;
            vertical-align: middle;

            &.imgAct {
                display: none;
            }
        }

        &.vip {
            padding: 7px;

            &>img {
                width: 19px;
                height: 19px;
            }
        }

        &.act,
        &:hover {
            &>.tips {
                display: block;
            }

            &>img {
                &.imgAct {
                    display: block;
                }

                &.img {
                    display: none;
                }
            }

            &.arrow {
                &::after {
                    border-color: #C1C1C1;
                }
            }
        }

        &.arrow {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                width: 6px;
                height: 6px;
                border-radius: 1px;
                border-top: 2px solid;
                border-right: 2px solid;
                border-color: #C1C1C1;
                right: 10px;
                top: 6px;
            }
        }
    }
}

.menus-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    margin-right: 10px;

    &>.menu-item {
        padding: 0;
        padding-left: 12px;
        height: 38px;
        border-radius: 6px;
        background: #363639;

        .text {
            color: #fff;
            font-size: 13px;
            line-height: 12px;
            width: calc(100% - 34px);
            text-align: left;
            font-weight: bold;
            text-align: center;
        }
    }
}

.menu-item-wrap {
    padding-right: 10px;
}

.mobile-sub-menus {
    // background: rgba(86, 92, 98, 1);
    border-radius: 0 0 12px 12px;
    padding: 4px;
    display: flex;
    flex-wrap: wrap;

    &>.sub-menus-item {
        border-radius: 12px;
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: $font-label-color;
        padding-left: .28rem;
        cursor: pointer;

        &>span {
            margin-left: .16rem;
        }

        &.act,
        &:hover {
            color: #fff;
            font-weight: bold;
            background: rgba(176, 181, 190, .2);
        }
    }
}

.menu-item {
    width: 100%;
    color: #C1C1C1;
    height: 38px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 0 13px 0 10px;
    cursor: pointer;
    position: relative;

    .icon {
        margin-right: 6px;

        i.iconfont {
            font-size: .36rem;
            color: $font-sub-color;
            margin: 0 .1rem;
            display: block;

            &.icon-kefuE {
                color: $font-theme-color;
            }
        }

        &>img {
            width: 33px;
            height: 33px;
            vertical-align: middle;

            &.imgAct {
                display: none;
            }
        }

        &.vip {
            padding: 7px;

            &>img {
                width: 19px;
                height: 19px;
            }
        }
    }

    &.act,
    &:hover {
        color: #fff;
        background: #363639;

        .icon {
            &>img {
                &.imgAct {
                    display: block;
                }

                &.img {
                    display: none;
                }
            }
        }

        &.arrow {
            &::after {
                border-color: #fff;
            }
        }
    }

    .arrow {
        position: absolute;
        color: #A0A4A7;
        width: .32rem;
        height: .32rem;
        top: calc(50% - .16rem);
        right: 0;
        font-size: .24rem;
        transform: rotate(-90deg);
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, .1);
        border-radius: 4px;
        margin-right: .2rem;
        padding-top: .05rem;

        // &::after {
        //     content: "";
        //     position: absolute;
        //     width: 10px;
        //     height: 10px;
        //     border-radius: 1px;
        //     border-top: 2px solid;
        //     border-right: 2px solid;
        //     border-color: #516382;
        //     right: 16px;
        //     top: 18px;
        //     transform: rotate(45deg);
        //     transition-duration: 0.3s;
        // }

        &.botArrow {
            transform: rotate(-180deg);
        }

        &.topArrow {
            transform: rotate(0deg);
        }
    }
}

.game_classify {
    margin: 24px 0;
    width: 100%;
    margin-top: 12px;

    .game_classify_top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 4px;
        padding-right: 10px;

        &>div {
            width: 33.3333333%;
            height: 46px;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(131, 129, 130, 1);
            color: #C1C1C1;
            cursor: pointer;

            &>i {
                font-size: 20px;
            }

            &.act,
            &:hover:not(.disabled) {
                color: $font-btn-color;
                background: rgba(255, 254, 249, 1);
            }

            &.disabled {
                opacity: .4;
                cursor: not-allowed;
            }
        }
    }

    .game_classify_title {
        text-align: left;
        color: #fff;
        margin-bottom: 6px;
    }
}

.menu-group {
    background: rgba(129, 106, 166, 0.08);
    border-radius: 12px;
    margin-top: 4px;
    overflow: hidden;
    margin-right: 10px;

    .menu-item {
        margin-top: 0;
        border-radius: 0;
    }
}

.sub-menus {
    height: 100%;
    position: absolute;
    width: 200px;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $bg-box-color;
    top: 0;
    right: -200px;
    box-shadow: 5px 0 5px #00000030;
    display: none;

    &.hover {
        display: block;
    }
    .sub-menus-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 0.8rem;
        padding: 0 18px;
        color: $font-sub-color;
        cursor: pointer;

        ::v-deep .van-image {
            .van-image__img {
                filter: grayscale(100%);

                &:hover {
                    filter: grayscale(0%);
                }
            }
        }

        &>span {
            margin-left: .16rem;
        }

        &.act {
            background: #838182;

            ::v-deep .van-image {
                .van-image__img {
                    filter: grayscale(0%) !important;
                }
            }
        }

        &:hover {
            color: #fff;
            font-weight: bold;
            background: #838182;
            ::v-deep .van-image {
                .van-image__img {
                    filter: grayscale(0%) !important;
                }
            }
        }
    }
}

.sidebar_footer {
    .language {
        display: flex;
        align-items: center;
        color: #FAF8E6;
        // background: #2F3E7A;
        // border: .01rem solid #8D8C96;
        border-radius: .06rem;
        padding: 0 .16rem 0 .1rem;
        font-size: .24rem;
        position: relative;
        cursor: pointer;
        margin-right: .2rem;

        .nowLang {
            margin-left: .06rem;
        }

        .arrow {
            width: .14rem;
            height: .14rem;
            border-top: .02rem solid #FFFFFF;
            border-right: .02rem solid #FFFFFF;
            position: absolute;
            top: calc(50% - .1rem);
            right: .16rem;
            transform: rotate(135deg);
        }

        &.act,
        &:hover {
            color: #fff;
            background: #363639;
        }
    }

    .mui-1uvz28q {
        place-content: center;
        -webkit-box-align: center;
        align-items: center;
        display: flex;
        flex-flow: row;
        -webkit-box-pack: center;
        overflow: hidden;
        width: 100%;
        animation: 0.2s ease 0s 1 normal none running animation-yvxynm;
        gap: 20px;
        margin-top: 24px;
        margin-bottom: 24px;
        padding-bottom: 0px;

        &>div {
            cursor: pointer;
        }
    }
}

html[data-device-nodesktop="1"] {
    .PC-hide {
        display: block;
    }
}

html[data-device-nodesktop="0"] {
    .PC-hide {
        display: none;
    }
}</style>
