<template>
    <div class="popup" :class="classObj" v-if="isLogin" @click="closeSelectPop">
        <van-popup :value="show" @click-overlay="close" @open="openPop">
            <div class="content">
                <div class="header">
                    <div>{{ $t("Transaction.Transaction") }}</div>
                    <div class="close">
                        <i class="iconfont icon-s-cuowu-guanbi" @click="close"></i>
                    </div>
                </div>
                <div class="box">
                    <div class="chose_box">
                        <div class="type_box">
                            <div class="nowType" @click.stop="showDateList = !showDateList">
                                <div>{{ dateRange[dateAct].time }}</div>
                                <i class="iconfont icon-open" :class="{ act: showDateList }"></i>
                            </div>
                            <div class="types" :class="{ act: showDateList }">
                                <div class="type" v-for="(item, index) in dateRange" :key="index"
                                    :class="{ act: index === dateAct }"
                                    @click="onChange(index, transationAct, stateAct)">
                                    {{ item.time }}
                                </div>
                            </div>
                        </div>
                        <div class="type_box">
                            <div class="nowType" @click.stop="showTransactionList = !showTransactionList">
                                <div>{{ transationTypes[transationAct].type }}</div>
                                <i class="iconfont icon-open" :class="{ act: showTransactionList }"></i>
                            </div>
                            <div class="types" :class="{ act: showTransactionList }">
                                <div class="type" v-for="(item, index) in transationTypes" :key="index"
                                    :class="{ act: index === transationAct }"
                                    @click="onChange(dateAct, index, stateAct)">
                                    {{ item.type }}
                                </div>
                            </div>
                        </div>
                        <div class="type_box">
                            <div class="nowType" @click.stop="showStateList = !showStateList">
                                <div>{{ stateTypes[stateAct].type }}</div>
                                <i class="iconfont icon-open" :class="{ act: showStateList }"></i>
                            </div>
                            <div class="types" :class="{ act: showStateList }">
                                <div class="type" v-for="(item, index) in stateTypes" :key="index"
                                    :class="{ act: index === stateAct }"
                                    @click="onChange(dateAct, transationAct, index)">
                                    {{ item.type }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="list_content">
                        <van-list v-model="loading" :finished="!hasMore" loading-text="" @load="loadMore" class="list"
                            offset="50" :immediate-check=false v-if="list.length !== 0">
                            <div class="list_item" v-for="(item, index) in list" :key="index">
                                <div v-if="transationAct === 0" @click.stop="toDetail(item)">
                                    <div>
                                        <div>
                                            {{ $t("MyProfile.ID") }}:{{ item.orderNo }}
                                        </div>
                                        <div>
                                            {{ $t("Transaction.TotalAmount") }}:{{ item.realityAmt }}
                                        </div>
                                        <div>
                                            {{ $t("Transaction.DepositValue") }}:{{ item.orderAmt }}
                                        </div>
                                        <div>
                                            {{ $t("Transaction.Bonus") }}:{{ item.giftAmt }}
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            {{ item.createTime }}
                                        </div>
                                        <div>
                                            <span v-if="item.rechargeOrderState === 'Fail'">{{
                                                $t("Transaction.Failed") }}</span>
                                            <span v-if="item.rechargeOrderState === 'CANCEL'">{{
                                                $t("Transaction.Cancel") }}</span>
                                            <span v-if="item.rechargeOrderState === 'WaitPay'">{{
                                                $t("Transaction.WaitPay") }}</span>
                                            <span v-if="item.rechargeOrderState === 'Paying'">{{
                                                $t("Transaction.Paying") }}</span>
                                            <span v-if="item.rechargeOrderState === 'PaySuccess'">{{
                                                $t("Transaction.PaySuccess") }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="transationAct === 1">
                                    <div>
                                        <div>
                                            {{ $t("MyProfile.ID") }}:{{ item.orderNo }}
                                        </div>
                                        <div>
                                            {{ $t("Transaction.Received") }}:{{ item.orderRealityWithdrawAmt }}
                                        </div>
                                        <div>
                                            {{ $t("Transaction.WithdrawBonus") }}:{{ item.orderWithdrawAmt }}
                                        </div>
                                        <div>
                                            {{ $t("Transaction.Rate") }}:{{ item.handlingAmt }}
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            {{ item.createTime }}
                                        </div>
                                        <div>
                                            <span v-if="item.showWithdrawOrderState === 'WithdrawFail'">{{
                                                $t("Transaction.WithdrawFail") }}</span>
                                            <span v-if="item.showWithdrawOrderState === 'Withdrawing'">{{
                                                $t("Transaction.Withdrawing") }}</span>
                                            <span v-if="item.showWithdrawOrderState === 'WithdrawSuccess'">{{
                                                $t("Transaction.WithdrawSuccess") }}</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </van-list>
                        <div class="empty" v-else>
                            <img src="@/assets/common/empty.png" alt="" class="empty_img">
                            <div>{{ $t("lang.NoData") }}</div>
                        </div>
                        <div v-if="loading && list.length !== 0" class="loading">
                            <a-spin>
                                <a-icon slot="indicator" type="loading" spin />
                            </a-spin>
                            {{ $t("lang.Loading") }}
                        </div>
                        <div v-if="!hasMore && list.length !== 0" class="loading">
                            {{ $t("lang.NoMore") }}
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>
        <depositDetail ref="depositDetail" :details="depositDetails" @onContinue="onContinueDeposit"></depositDetail>
    </div>
</template>

<script>
import {
    getDepositRecords,
    getWithdrawRecords
} from '@/api/records'
import moment from 'moment'
import { mapState } from 'vuex'
import depositDetail from '@/components/depositPopup/depositDetail.vue'
export default {
    name: 'transaction',
    components: { depositDetail },
    data() {
        return {
            firstLoaded: true,
            open: false,
            list: [],
            allList: [],
            pageSize: 10,
            pageNo: 0,
            loading: false,
            hasMore: true,
            showDateList: false,
            showTransactionList: false,
            showStateList: false,
            dateAct: 0,
            transationAct: 0, // 0 充值 1 提现
            stateAct: 0,
            date: {},
            dateRange: [
                {
                    id: 0,
                    time: this.$t('Transaction.Today')
                },
                {
                    id: 1,
                    time: this.$t('Transaction.Yesterday')
                },
                {
                    id: 2,
                    time: this.$t('Transaction.LastThreeDays')
                },
                {
                    id: 3,
                    time: this.$t('Transaction.LastSevenDays')
                },
                {
                    id: 4,
                    time: this.$t('Transaction.LastFifteenDays')
                },
                {
                    id: 5,
                    time: this.$t('Transaction.LastThiryDays')
                }
            ],
            transationTypes: [
                {
                    id: 0,
                    type: this.$t('deposit.Deposit')
                },
                {
                    id: 1,
                    type: this.$t('Withdraw.Withdraw')
                }
            ],
            stateTypes: [
                {
                    id: 0,
                    type: this.$t('Transaction.AllState')
                },
                {
                    id: 1,
                    type: this.$t('Transaction.Processing')
                },
                {
                    id: 2,
                    type: this.$t('Transaction.Successful')
                },
                {
                    id: 3,
                    type: this.$t('Transaction.Failed')
                },
                {
                    id: 4,
                    type: this.$t('Transaction.WaitPay')
                },
                {
                    id: 5,
                    type: this.$t('Transaction.Cancel')
                }
            ],
            depositDetails: {}
        }
    },
    computed: {
        isLogin() {
            return !!this.$store.getters.token
        },
        ...mapState({
            device: state => state.app.device
        }),
        classObj() {
            return {
                mobile: this.device === 'mobile'
            }
        },
        userInfo() {
            return this.$store.getters.userInfo
        },
        show: {
            get() {
                return this.$store.getters.isShowTransactionPopup
            },
            set(val) {
                return val
            }
        }
    },
    created() {
        this.todayDate = moment()
    },
    methods: {
        moment,
        close() {
            this.$store.dispatch('user/setIsShowTransactionPopup', false)
        },
        openPop() {
            this.dateRange = [
                {
                    id: 0,
                    time: this.$t('Transaction.Today')
                },
                {
                    id: 1,
                    time: this.$t('Transaction.Yesterday')
                },
                {
                    id: 2,
                    time: this.$t('Transaction.LastThreeDays')
                },
                {
                    id: 3,
                    time: this.$t('Transaction.LastSevenDays')
                },
                {
                    id: 4,
                    time: this.$t('Transaction.LastFifteenDays')
                },
                {
                    id: 5,
                    time: this.$t('Transaction.LastThiryDays')
                }
            ]
            this.transationTypes = [
                {
                    id: 0,
                    type: this.$t('deposit.Deposit')
                },
                {
                    id: 1,
                    type: this.$t('Withdraw.Withdraw')
                }
            ]
            if (this.firstLoaded) {
                this.init()
            }
        },
        init() {
            this.pageSize = 10
            this.pageNo = 0
            this.list = []
            this.onChange(this.dateAct, this.transationAct, this.stateAct)
        },
        changeStatusList(type) {
            if (type === 0) {
                this.stateTypes = [
                    {
                        id: 0,
                        type: this.$t('Transaction.AllState')
                    },
                    {
                        id: 1,
                        type: this.$t('Transaction.Processing')
                    },
                    {
                        id: 2,
                        type: this.$t('Transaction.Successful')
                    },
                    {
                        id: 3,
                        type: this.$t('Transaction.Failed')
                    },
                    {
                        id: 4,
                        type: this.$t('Transaction.WaitPay')
                    },
                    {
                        id: 5,
                        type: this.$t('Transaction.Cancel')
                    }
                ]
            } else {
                this.stateTypes = [
                    {
                        id: 0,
                        type: this.$t('Transaction.AllState')
                    },
                    {
                        id: 1,
                        type: this.$t('Transaction.Processing')
                    },
                    {
                        id: 2,
                        type: this.$t('Transaction.Successful')
                    },
                    {
                        id: 3,
                        type: this.$t('Transaction.Failed')
                    }
                ]
            }
        },
        onChange(time, type, state) {
            // console.log(time, type, state)
            if (!this.firstLoaded) {
                if (this.dateAct === time && this.transationAct === type && this.stateAct === state) return
            }
            this.changeStatusList(type)
            this.dateAct = time
            this.transationAct = type
            this.stateAct = state
            const a = this.getDate()
            if (time === 0) {
                this.date = a.today
            } else if (time === 1) {
                this.date = a.yesterday
            } else if (time === 2) {
                this.date = a.lastThreeDays
            } else if (time === 3) {
                this.date = a.lastSevenDays
            } else if (time === 4) {
                this.date = a.halfMonth
            } else if (time === 5) {
                this.date = a.thirtyDaysAgo
            }
            this.pageSize = 10
            this.pageNo = 0
            this.list = []
            if (this.transationAct === 0) {
                this.getDepositRecords()
            } else {
                this.getWithdrawRecords()
            }
        },
        closeSelectPop() {
            this.showDateList = false
            this.showTransactionList = false
            this.showStateList = false
        },
        getDate() {
            const todayStart = moment().format('YYYY-MM-DD 00:00:00')
            const todayEnd = moment().format('YYYY-MM-DD 23:59:59')
            const yesterdayStart = moment().subtract(1, 'day').format('YYYY-MM-DD 00:00:00')
            const yesterdayEnd = moment().subtract(1, 'day').format('YYYY-MM-DD 23:59:59')
            const lastThreeDays = moment().add(-3, 'day').format('YYYY-MM-DD 00:00:00')
            const lastSevenDays = moment().add(-7, 'day').format('YYYY-MM-DD 00:00:00')
            const fifteenDaysAgo = moment().subtract(15, 'days').format('YYYY-MM-DD 00:00:00')
            const thirtyDaysAgo = moment().subtract(30, 'days').format('YYYY-MM-DD 00:00:00')
            return {
                today: {
                    startDay: todayStart,
                    endDay: todayEnd
                },
                yesterday: {
                    startDay: yesterdayStart,
                    endDay: yesterdayEnd
                },
                lastThreeDays: {
                    startDay: lastThreeDays,
                    endDay: todayEnd
                },
                lastSevenDays: {
                    startDay: lastSevenDays,
                    endDay: todayEnd
                },
                halfMonth: {
                    startDay: fifteenDaysAgo,
                    endDay: todayEnd
                },
                thirtyDaysAgo: {
                    startDay: thirtyDaysAgo,
                    endDay: todayEnd
                }
            }
        },
        async getDepositRecords() {
            this.loading = true
            this.pageNo++
            let nowState = ''
            if (this.stateAct === 1) {
                nowState = 'Paying'
            } else if (this.stateAct === 2) {
                nowState = 'PaySuccess'
            } else if (this.stateAct === 3) {
                nowState = 'Fail'
            } else if (this.stateAct === 4) {
                nowState = 'WaitPay'
            } else if (this.stateAct === 5) {
                nowState = 'CANCEL'
            } else {
                nowState = null
            }
            const curData = {
                startTime: this.date.startDay,
                endTime: this.date.endDay,
                pageNo: this.pageNo,
                pageSize: this.pageSize,
                rechargeOrderState: nowState
            }
            const {
                result,
                code
            } = await getDepositRecords(curData)
            // console.log(result)
            if (code === 0) {
                this.list = [...this.list, ...result.list]
                this.hasMore = Number(result.total) > this.list.length
                this.firstLoaded = false
                this.loading = false
                // this.getNowList()
            }
        },
        async getWithdrawRecords() {
            this.loading = true
            this.pageNo++
            let nowState = ''
            if (this.stateAct === 1) {
                nowState = 'Withdrawing'
            } else if (this.stateAct === 2) {
                nowState = 'WithdrawSuccess'
            } else if (this.stateAct === 3) {
                nowState = 'WithdrawFail'
            } else {
                nowState = null
            }
            const curData = {
                startTime: this.date.startDay,
                endTime: this.date.endDay,
                pageNo: this.pageNo,
                pageSize: this.pageSize,
                showWithdrawOrderState: nowState
            }
            const {
                result,
                code
            } = await getWithdrawRecords(curData)
            if (code === 0) {
                this.list = [...this.list, ...result.list]
                this.hasMore = Number(result.total) > this.list.length
                this.firstLoaded = false
                this.loading = false
                // this.getNowList()
            }
        },
        getNowList() {
            if (this.transationAct === 0) {
                if (this.stateAct === 0) {
                    this.list = this.allList
                } else if (this.stateAct === 1) {
                    this.list = this.allList.filter(record => record.rechargeOrderState === 'Paying')
                } else if (this.stateAct === 2) {
                    this.list = this.allList.filter(record => record.rechargeOrderState === 'PaySuccess')
                } else if (this.stateAct === 3) {
                    this.list = this.allList.filter(record => record.rechargeOrderState === 'Fail')
                }
            } else if (this.transationAct === 1) {
                if (this.stateAct === 0) {
                    this.list = this.allList
                } else if (this.stateAct === 1) {
                    this.list = this.allList.filter(record => record.withdrawOrderState === 'NotDealWith')
                } else if (this.stateAct === 2) {
                    this.list = this.allList.filter(record => record.withdrawOrderState === 'WithdrawSuccess')
                } else if (this.stateAct === 3) {
                    this.list = this.allList.filter(record => record.withdrawOrderState === 'WithdrawFail')
                }
            }
            // console.log('111', this.list)
        },
        loadMore() {
            if (this.transationAct === 0) {
                this.getDepositRecords()
            } else {
                this.transationAct()
            }
        },
        toDetail(item) {
            this.$nextTick(() => {
                this.depositDetails = item
                this.$refs.depositDetail.open()
            })
        },
        onContinueDeposit() {
            this.close()
            this.$store.dispatch('user/setIsShowDepositPopup', true)
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.popup {
    text-align: left;

    &.mobile {

        .van-popup {
            &.van-popup--center {
                top: 0;
                left: 0;
                transform: translate3d(0, 0, 0);
                width: 100% !important;
                height: 100% !important;
            }

            .content {
                background: $bg-color;
                width: 100%;
                height: 100vh;

                .header {
                    height: 1.12rem;
                    padding: 0 .3rem;
                    background: $bg-navbar-color;

                    &>div:first-child {
                        font-size: .32rem;
                    }

                    .close {
                        font-size: .32rem;
                    }
                }

                .box {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    padding: .3rem;
                }

                .chose_box {

                    >div {
                        font-size: .24rem;
                    }

                    .type_box {
                        .nowType {
                            background: $bg-box-color;
                        }

                        .types {
                            bottom: -0.1rem;
                        }
                    }
                }

                .list_content {
                    margin: .2rem 0;
                    flex: 1;

                    .list {
                        padding: .2rem;
                        row-gap: .2rem;
                    }

                    .loading {
                        font-size: .28rem;
                    }

                    .empty {
                        font-size: .3rem;

                        .empty_img {
                            width: 2.8rem;
                        }
                    }
                }
            }
        }
    }

    .van-popup {
        background: transparent;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .content {
            background: $bg-popup-color;
            width: 800px;
            height: 700px;
            border-radius: .24rem;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            .header {
                display: flex;
                height: 76px;
                padding: 0 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: $bg-popup-navbar-color;

                &>div:first-child {
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                }

                .close {
                    color: $font-iconfont-color;
                    font-size: 16px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: all 0.3s;

                    &:hover {
                        color: #ffffff;
                        rotate: -180deg;
                    }
                }
            }

            .box {
                flex: 1;
                overflow-y: scroll;
                display: flex;
                flex-direction: column;
                padding: 10px 30px 30px;
            }

            .chose_box {
                display: flex;
                gap: 10px;

                >div {
                    flex: 1;
                }

                .type_box {
                    position: relative;
                    width: 100%;

                    .nowType {
                        height: .8rem;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        background: $bg-menu-color;
                        border-radius: .24rem;
                        padding: 0 .24rem;
                        width: 100%;
                        color: #ffffff;
                        cursor: pointer;

                        i {
                            color: $font-iconfont-color;
                            transition: all 0.3s;

                            &.act {
                                color: #ffffff;
                                font-weight: bold;
                                transform: rotate(180deg);
                            }
                        }
                    }

                    .types {
                        position: absolute;
                        z-index: 1;
                        // top: 45px;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        background: $bg-box-color;
                        transition: all 0.3s ease-in-out;
                        border-radius: .24rem;
                        overflow: hidden;
                        transform-origin: 0 0;
                        transform: translate3d(0, 100%, 0) scaleY(0);
                        opacity: 0;
                        box-shadow: 0 4px 20px #0006;

                        &.act {
                            transform: translate3d(0, 100%, 0) scaleY(1);
                            visibility: visible;
                            opacity: 1;
                        }

                        .type {
                            color: $font-sub-color;
                            height: .8rem;
                            display: flex;
                            align-items: center;
                            // line-height: .8rem;
                            font-size: .24rem;
                            padding: 0 .24rem;
                            cursor: pointer;

                            &.act {
                                font-weight: bold;
                                color: $font-btn-color;
                                background: $bg-btn-color;
                            }
                        }
                    }
                }
            }

            .list_content {
                flex: 1;
                margin-top: 10px;
                background: $bg-menu-color;
                border-radius: .24rem;

                .list {
                    padding: 15px;
                    display: flex;
                    flex-direction: column;
                    row-gap: 10px;

                    .list_item {
                        padding: .2rem;
                        background: $bg-box-color;
                        border-radius: .24rem;

                        >div {
                            display: flex;
                            justify-content: space-between;

                            >div {
                                &:first-child {
                                    display: flex;
                                    flex-direction: column;
                                    row-gap: .08rem;
                                    color: $font-sub-color;
                                    font-size: .22rem;

                                    >div {
                                        &:first-child {
                                            font-size: .22rem;
                                        }

                                        &:nth-child(2) {
                                            font-size: .28rem;
                                            font-weight: bold;
                                        }

                                        &:nth-child(3) {}

                                        &:last-child {}
                                    }
                                }

                                &:last-child {
                                    >div {
                                        &:first-child {
                                            color: $font-label-color;
                                            text-align: right;
                                        }

                                        &:last-child {
                                            font-size: .32rem;
                                            color: $font-theme-color;
                                            text-align: right;
                                        }
                                    }
                                }
                            }
                        }

                        ::v-deep .van-loading {
                            display: none;
                        }
                    }
                }

                .loading {
                    font-size: 14px;
                    color: $font-sub-color;
                    text-align: center;
                    margin: .1rem 0;
                }

                .empty {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    color: $font-sub-color;
                    font-size: .32rem;
                    text-align: center;

                    .empty_img {
                        width: 170px;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}
</style>
