<template>
    <div class="popup" :class="classObj">
        <van-popup :value="show" @click-overlay="close" @open="open">
            <div class="content">
                <div class="header">
                    <div>
                        {{ $t("RedPacket.RedPacket") }}
                        <!-- <i class="iconfont icon-changjianwentixiangguanwenti"></i> -->
                    </div>
                    <div class="close">
                        <i class="iconfont icon-s-cuowu-guanbi" @click="close"></i>
                    </div>
                </div>
                <div class="box">
                    <div>
                        <!-- <div class="bonus_box">
                            <div>₦ 1,000,000.00</div>
                            <div> Per time</div>
                        </div> -->
                        <div class="time_box">
                            <div class="top">{{ $t("RedPacket.NextEvent") }}:</div>
                            <div class="bottom" v-if="list.length">
                                <div v-for="(item, index) in list" :key="index">
                                    {{ item.startTime }}-{{ item.endTime }}
                                </div>
                            </div>
                            <div v-else class="empty_img">
                                <img src="@/assets/common/empty.png" alt="">
                            </div>
                        </div>
                        <!-- <div class="extra_box">
                            <div>
                                Extra Rain Event: (No. 1~7, Every Friday, Saturday and Sunday.)
                            </div>
                            <div></div>
                        </div> -->
                        <div class="rules" v-html="rulesDesc"></div>
                    </div>
                    <div>
                        <div class="btn" @click="toPendingPop">
                            {{ $t("RedPacket.Participate") }}
                            <!-- Deposit and participate -->
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { getTimedRedPacketInfo } from '@/api/timedRedPacket'
import {
    getEventsHomeShow
} from '@/api/home'
export default {
    name: 'redPacket',
    components: {
    },
    data() {
        return {
            firstLoaded: true,
            list: [],
            rulesDesc: ''
        }
    },
    computed: {
        ...mapState({
            device: state => state.app.device
        }),
        classObj() {
            return {
                mobile: this.device === 'mobile'
            }
        },
        eventId() {
            return this.$store.state.user.redPacketEventId
        },
        show: {
            get() {
                return this.$store.getters.isShowRedPacketPopup
            },
            set(val) {
                return val
            }
        }
    },
    created() { },
    methods: {
        close() {
            this.$store.dispatch('user/setIsShowRedPacketPopup', { id: '', show: false })
        },
        open() {
            if (this.firstLoaded) {
                this.init()
            }
        },
        init() {
            this.getInfo()
        },
        getInfo() {
            if (this.eventId) {
                this.getRedPacketInfo(this.eventId)
            } else {
                getEventsHomeShow().then((res) => {
                    if (res.code) return
                    const D = res.result.eventManageList
                    const item = D.filter(item => item.eventType === 'redPacket')
                    if (item.length) {
                        this.getRedPacketInfo(item[0].id)
                    }
                })
            }
        },
        getRedPacketInfo(id) {
            getTimedRedPacketInfo({ id }).then((res) => {
                if (res.code) return
                const D = res.result
                // console.log(D)
                this.list = D.value0.redPacketDetailModelVoList
                this.rulesDesc = D.value0.eventContent
            })
        },
        toPendingPop() {
            this.$store.dispatch('user/setIsShowPendingPopup', true)
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.popup {
    &.mobile {
        .van-popup {
            padding: 0 8.53vw;
            width: 100%;

            .content {
                width: 100%;
                height: 8.5rem;

                .header {
                    height: .88rem;
                    padding: 0 3.73vw;

                    &>div:first-child {
                        font-size: .3rem;

                        i {
                            font-size: .4rem;
                        }
                    }
                }

                .box {
                    flex: 1;
                    padding: .2rem 5.33vw .4rem;

                    .bonus_box {
                        >div {
                            &:first-child {
                                font-size: .64rem;
                                height: .64rem;
                                line-height: .64rem;
                            }
                        }
                    }

                    .time_box {

                        .top {
                            height: .52rem;
                            line-height: .52rem;
                            padding: 0 .16rem;
                            font-size: .24rem;
                        }

                        .bottom {

                            >div {
                                font-size: .24rem;
                            }
                        }
                    }

                    .extra_box {

                        >div {
                            &:first-child {
                                padding: .12rem .16rem;
                                line-height: .24rem;
                                font-size: .24rem;
                            }
                        }
                    }

                    .rules {
                        text-align: left;
                        margin-top: .2rem;
                        font-size: .22rem;
                    }

                    .btn {
                        height: .8rem;
                        line-height: .8rem;
                    }
                }
            }
        }
    }

    .van-popup {
        background: transparent;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .content {
            width: 500px;
            height: 700px;
            border-radius: .24rem;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            color: #ffffff;
            background: $bg-popup-color;
            // background: url('@/assets/redPacket/redpacket_login.png') no-repeat;
            // background-size: 100% 140%;

            .header {
                display: flex;
                height: 76px;
                padding: 0 .6rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: $bg-popup-navbar-color;

                &>div:first-child {
                    font-size: .4rem;
                    font-weight: bold;
                    color: #fff;

                    i {
                        color: #773c1a;
                        font-size: .4rem;
                    }
                }

                .close {
                    color: $font-iconfont-color;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: all 0.3s;

                    i {
                        font-size: .32rem;
                        font-weight: bold;
                    }

                    &:hover {
                        color: #ffffff;
                        rotate: -180deg;
                    }
                }
            }

            .box {
                flex: 1;
                padding: 40px 30px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                overflow-y: scroll;

                .bonus_box {
                    >div {
                        text-align: center;

                        &:first-child {
                            font-size: 40px;
                            font-weight: bold;
                            background: linear-gradient(90deg, #f90, #ec0039);
                            -webkit-background-clip: text;
                            background-clip: text;
                            color: transparent;
                            height: 40px;
                            line-height: 40px;
                            font-weight: bold;
                        }

                        &:last-child {
                            font-size: .24rem;
                            height: .24rem;
                            line-height: .24rem;
                        }
                    }
                }

                .time_box {
                    border-radius: .24rem;
                    overflow: hidden;
                    margin-top: .2rem;
                    position: relative;

                    .top {
                        background: linear-gradient(163deg, #16191E, #3B404C);
                        // background: rgba(0, 0, 0, .1);
                        height: .66rem;
                        line-height: .66rem;
                        padding: 0 .24rem;
                        text-align: left;
                    }

                    .bottom {
                        text-align: center;
                        display: flex;
                        background: $bg-menu-color;
                        padding: .2rem 0;

                        >div {
                            flex: 1;
                            color: #ffffff;
                            font-size: .32rem;
                            border-right: .02rem solid hsla(0, 0%, 100%, .3);

                            &:last-child {
                                border: 0;
                            }
                        }
                    }

                    .empty_img {
                        width: 100%;
                        text-align: center;

                        img {
                            width: 2.8rem;
                            object-fit: contain;
                            margin-top: 110px;
                        }
                    }
                }

                .extra_box {
                    overflow: hidden;
                    border-radius: .24rem;
                    margin-top: .2rem;
                    text-align: left;

                    >div {
                        &:first-child {
                            padding: .16rem 12px;
                            line-height: .28rem;
                            font-size: 14px;
                            background: rgba(0, 0, 0, .1);
                        }

                        &:last-child {
                            background: $bg-menu-color;
                            height: .4rem;
                        }
                    }
                }

                .rules {
                    text-align: left;
                    margin-top: .2rem;
                    font-size: .28rem;
                }

                .btn {
                    height: 46px;
                    line-height: 46px;
                    border-radius: .24rem;
                    color: $font-btn-color;
                    font-size: 17px;
                    background: $bg-btn-color;
                    cursor: pointer;
                    font-weight: bold;
                }
            }
        }
    }
}
</style>
