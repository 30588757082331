import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Layout,
        redirect: '/Home',
        children: [
            {
                path: 'Home',
                component: () => import('@/views/home/index'),
                name: 'Home'
            },
            {
                path: 'Event',
                component: () => import('@/views/event/index'),
                name: 'Event'
            },
            {
                path: 'Affiliate',
                component: () => import('@/views/affiliate/index'),
                name: 'Affiliate'
            },
            {
                path: 'Person',
                component: () => import('@/views/person/index'),
                name: 'Person',
                meta: { isMobileHideFooter: true }
            },
            {
                path: 'Casino/:timestamp/:params',
                component: () => import('@/views/casino/index'),
                name: 'Casino',
                meta: { isMobileOnlyCustomer: true }
            },
            {
                path: 'bonus-center',
                component: () => import('@/views/bonusCenter/index'),
                name: 'bonus-center'
            },
            {
                path: 'vip',
                component: () => import('@/views/vip/index'),
                name: 'vip'
            },
            {
                path: 'wallet',
                component: () => import('@/views/wallet/index'),
                name: 'wallet'
            },
            {
                path: 'setting',
                component: () => import('@/views/setting/index'),
                name: 'setting'
            },
            {
                path: 'PromotionCustom/:timestamp/:params',
                component: () => import('@/views/promotionCustom/index'),
                name: 'PromotionCustom',
                meta: { isMobileHideFooter: true, isMobileHideHeader: true, isMobileHideTabbar: true }
            },
            {
                path: 'MsgDetail/:timestamp/:params',
                component: () => import('@/views/msgDetail/index'),
                name: 'MsgDetail',
                meta: { isMobileHideFooter: true, isMobileHideHeader: true, isMobileHideTabbar: true }
            }
        ]
    },
    {
        path: '/depositWv',
        component: () => import('@/views/depositWv/index')
    },
    {
        path: '/SignIn',
        component: () => import('@/views/signIn/index')
    },
    {
        path: '/Turntable',
        component: () => import('@/views/turntable/index')
    },
    {
        path: '/SubGame',
        component: () => import('@/views/subGame/index')
    },
    {
        path: '/Search',
        component: () => import('@/views/search/index')
    },
    {
        path: '/Agent',
        component: () => import('@/views/agent/index')
    },
    {
        path: '/TimedRedPacket',
        component: () => import('@/views/timedRedPacket/index')
    },
    {
        path: '/Pending',
        component: () => import('@/views/pending/index')
    },
    {
        path: '/PendingHistory',
        component: () => import('@/views/pendingHistory/index')
    },
    {
        path: '/LossSupport',
        component: () => import('@/views/lossSupport/index')
    },
    {
        path: '/Rebate',
        component: () => import('@/views/rebate/index')
    },
    {
        path: '/downAppPWA',
        component: () => import('@/views/downAppPWA/index'),
        name: 'downAppPWA'
    },
    {
        path: '/Validate',
        component: () => import('@/views/Validate/index'),
        name: 'Validate'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
