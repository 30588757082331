<template>
    <div class="content">
        <div class="LogReg">
            <statusBar></statusBar>
            <div class="header">
                <img class="logo" :src="logoUrl" />
                <div class="close">
                    <i class="iconfont icon-s-cuowu-guanbi" @click="close"></i>
                </div>
            </div>
            <div class="LogReg-content">
                <div class="tabs">
                    <div class="tab-item" :class="{ 'act': act == 2 }" @click="onChangeTab(2)">{{ $t("login.SignIn") }}
                    </div>
                    <div class="tab-item" :class="{ 'act': act == 1 }" @click="onChangeTab(1)">{{ $t("login.SignUp") }}
                    </div>
                </div>
                <div class="form-box">
                    <div class="form" v-if="act == 1">
                        <a-form-model ref="ruleForm1" :model="form1" :rules="rules1" @submit.native.prevent>
                            <a-form-model-item ref="phone" label="" prop="phone">
                                <a-input v-model="form1.phone" type="number" :placeholder="$t('login.phone')" class="phone">
                                    <!-- <language slot="prefix" :show="true" :showIcon="true"></language> -->
                                    <i slot="prefix" class="iconfont icon-shouji1" />
                                    <span slot="prefix" class="phoneCode" :class="{act: isPhoneCode}" @click="onShowPhoneCodePopup">+{{ phoneCode }}</span>
                                    <!-- <a-input v-model="phoneCode" type="number" class="phoneCode"></a-input> -->
                                </a-input>
                            </a-form-model-item>
                            <a-form-model-item ref="password" label="" prop="password">
                                <a-input-password v-model="form1.password" :placeholder="$t('login.password')"
                                    autocomplete="new-password" allowClear>
                                    <i slot="prefix" class="iconfont icon-mima" />
                                </a-input-password>
                            </a-form-model-item>
                            <a-form-model-item ref="password" label="" prop="matchPassword">
                                <a-input-password v-model="form1.matchPassword" :placeholder="$t('login.ConfirmPwdAgain')"
                                    autocomplete="new-password" allowClear>
                                    <i slot="prefix" class="iconfont icon-mima" />
                                </a-input-password>
                            </a-form-model-item>
                            <a-form-model-item ref="inviteUrl" label="" prop="inviteUrl">
                                <a-input v-model="form1.invitationCode" :disabled="!!$store.getters.invitCode"
                                    :placeholder="$t('login.InvitationCode')">
                                    <i slot="prefix" class="iconfont icon-shebeizhuce" />
                                </a-input>
                            </a-form-model-item>
                        </a-form-model>
                        <div class="remember">
                            <div class="check-box" @click="argeeService = !argeeService">
                                <i v-if="argeeService" class="iconfont icon-xuanzeyixuanze checked"></i>
                                <i v-else class="iconfont icon-a-xuanzeweixuanze"></i>
                            </div>
                            <div class="tips">
                                {{ $t('login.OverAgeAgree') }}
                                <span class="txt">《{{ $t("login.TermsOfService") }}》</span>
                            </div>
                        </div>
                        <a-button class="btn" @click="handleSubmit('ruleForm1')">{{ $t("login.SignUp") }}</a-button>
                    </div>
                    <div class="form" v-if="act == 2">
                        <a-form-model ref="ruleForm2" :model="form2" :rules="rules2" @submit.native.prevent>
                            <a-form-model-item ref="phone" label="" prop="phone">
                                <a-input v-model="form2.phone" :placeholder="$t('login.accountOrPhone')" class="account">
                                    <i slot="prefix" class="iconfont icon-shouji1" />
                                    <!-- <language slot="prefix" :show="true" :showIcon="true"></language> -->
                                    <!-- <span slot="prefix">{{ phoneCode }}</span> -->
                                </a-input>
                            </a-form-model-item>
                            <a-form-model-item ref="password" label="" prop="password">
                                <a-input-password v-model="form2.password" :placeholder="$t('login.password')"
                                    autocomplete="new-password" allowClear>
                                    <i slot="prefix" class="iconfont icon-mima" />
                                </a-input-password>
                            </a-form-model-item>
                        </a-form-model>
                        <div class="remember">
                            <div class="check-box" @click="rememberMe = !rememberMe">
                                <i v-if="rememberMe" class="iconfont icon-xuanzeyixuanze checked"></i>
                                <i v-else class="iconfont icon-a-xuanzeweixuanze"></i>
                            </div>
                            <div class="tips">
                                {{ $t("login.RememberYouraccountPassword") }}
                            </div>
                        </div>
                        <a-button class="btn" @click="handleSubmit('ruleForm2')">{{ $t("login.SignIn") }}</a-button>
                    </div>
                    <!-- 注册倒计时 -->
                    <div v-if="act == 1 && !isCanRegister" class="time_down_box">
                        <div class="time_down">
                            <div class="time_down_title">{{ $t("login.downTime") }}</div>
                            <div class="time_down_time">
                                <span>{{ $t("login.also") }}：</span>
                                <van-count-down :time="timestamp" :format="'DD ' + $t('login.day') + ' HH:mm:ss'">
                                </van-count-down>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="banner_pc">
            <div class="mui-7ak6qf">
                <picture>
                    <source type="image/webp" srcset="@/assets/common/luckycat-2.png"><img
                        src="/_next/static/media/auth.52b7a1d8.png" alt="Auth" loading="lazy" width="280" height="280"
                        style="object-fit: cover;">
                </picture>
                <div class="MuiBox-root mui-16z924v"><span
                        class="MuiTypography-root MuiTypography-title mui-16jo4oo">Welcome Bonus</span>
                    <div class="MuiBox-root mui-k008qs">
                        <h4 class="MuiTypography-root MuiTypography-h4 mui-1u4w7oe">+400%</h4>
                        <!-- <h4 class="MuiTypography-root MuiTypography-h4 mui-1c295ux">+</h4>
                        <h4 class="MuiTypography-root MuiTypography-h4 mui-1u4w7oe">250FS</h4> -->
                    </div>
                </div>
            </div>
        </div>

        <van-popup v-model="showPhoneCodePopup">
            <div class="phoneCode-content">
                <div class="header">
                    <div></div>
                    <div class="close">
                        <i class="iconfont icon-s-cuowu-guanbi" @click="showPhoneCodePopup = false"></i>
                    </div>
                </div>
                <a-input v-model="phoneCode" :maxLength="3" type="number" @change="phoneCodeChange"></a-input>
                <a-button class="btn" @click="showPhoneCodePopup = false">{{ $t("lang.confirm") }}</a-button>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { Toast } from 'vant'
import { login, getUserInfo, userRegister } from '@/api/user'
// import { getNationInfo } from '@/api/system'
import md5 from 'md5'
// import language from '@/components/language'
import statusBar from '@/components/statusBar'
import { domainName } from '@/common/http'
export default {
    name: 'accountForm',
    components: { statusBar },
    data() {
        const validatePass1 = (rule, value, callback) => {
            const reg = /^\d{9,12}$/
            if (!reg.test(value)) {
                callback(new Error(this.$t('login.PhoneFormat')))
            } else {
                callback()
            }
        }
        const validatePass2 = (rule, value, callback) => {
            const reg = /^(?=.*[a-zA-Z])[a-zA-Z0-9\W_]{8,16}$/g
            if (!reg.test(value)) {
                callback(new Error(this.$t('login.PasswordFormat')))
            } else {
                if (this.form1.password !== '') {
                    this.$refs.ruleForm1.validateField('password')
                }
                callback()
            }
        }
        const validatePass3 = (rule, value, callback) => {
            const reg = /^(?=.*[a-zA-Z])[a-zA-Z0-9\W_]{8,16}$/g
            if (!reg.test(value)) {
                callback(new Error(this.$t('login.PasswordFormat')))
            } else {
                if (this.form1.matchPassword !== '') {
                    this.$refs.ruleForm1.validateField('matchPassword')
                }
                callback()
            }
        }
        return {
            domainName,
            act: 1,
            argeeService: true,
            rememberMe: true,
            debounceTimer: null,
            form1: {
                phone: '',
                password: '',
                matchPassword: '',
                invitationCode: ''
            },
            rules1: {
                phone: [
                    { required: true, message: this.$t('login.PleaseEnterYourPhoneNumber'), trigger: 'blur' },
                    { validator: validatePass1, trigger: 'blur' }
                ],
                password: [
                    { required: true, message: this.$t('login.PleaseEnterPassword'), trigger: 'blur' },
                    { validator: validatePass2, trigger: 'blur' }
                ],
                matchPassword: [{ validator: validatePass3, trigger: 'blur' }]
            },
            form2: {
                phone: '',
                password: ''
            },
            rules2: {
                phone: [{ required: true, message: this.$t('login.PleaseEnterAccount'), trigger: 'blur' }],
                password: [{ required: true, message: this.$t('login.PleaseEnterPassword'), trigger: 'blur' }]
            },

            isCanRegister: true,
            timestamp: 0,
            timeData: {},

            phoneCode: '',
            showPhoneCodePopup: false,
            isPhoneCode: false
        }
    },
    props: {
        isCanClose: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        logoUrl2() {
            return this.$store.getters.sysConfig?.homeImageUrl
        },
        logoUrl() {
            return this.$store.getters.sysConfig?.homeLogoUrl
        }
    },
    created() {
        this.open()
    },
    methods: {
        close() {
            this.$emit('close')
        },
        open() {
            const that = this
            that.act = that.$store.getters.loginType
            if (that.act === 1) that.getDownTimes()
            that.form1.invitationCode = that.$store.getters.invitCode || ''
            const rememberInfo = this.$store.getters.rememberInfo
            const form = rememberInfo ? JSON.parse(rememberInfo) : null
            if (form) {
                this.form2.phone = form.phone
                this.form2.password = form.password
            }
            this.onClearValidate()
            // getNationInfo().then((res) => {
            //     if (!res) return
            //     if (res.code) return
            //     const D = res.result
            //     D.forEach(x => {
            //         if (this.BelongNation === x.enumName) {
            //             this.phoneCode = x.code
            //         }
            //     })
            // })
        },
        onChangeTab(type) {
            this.act = type
            this.onClearValidate()
            this.$store.dispatch('user/changeLoginType', type)
        },
        onClearValidate() {
            this.$nextTick(() => {
                if (this.act === 1) {
                    this.$refs.ruleForm1.clearValidate()
                }
                if (this.act === 2) {
                    this.$refs.ruleForm2.clearValidate()
                }
            })
        },
        handleSubmit(formName) {
            const that = this
            clearTimeout(that.debounceTimer)
            that.debounceTimer = setTimeout(() => {
                that.$refs[formName].validate(valid => {
                    if (valid) {
                        if (that.act === 1) {
                            const formData = that.form1
                            that.isPhoneCode = false
                            if (!that.phoneCode) {
                                that.isPhoneCode = true
                                Toast(that.$t('login.PleaseEnterPhoneCode'))
                                return
                            }
                            if (!that.argeeService) {
                                Toast(that.$t('login.PleaseAgreeOverAge'))
                                return
                            }
                            if (formData.password !== formData.matchPassword) {
                                Toast(that.$t('login.InconsistentPassword'))
                                return
                            }
                            clearTimeout(that.debounceTimer)
                            that.debounceTimer = setTimeout(() => {
                                const userAgent = navigator.userAgent
                                const params = {
                                    ...formData,
                                    password: md5(formData.password),
                                    matchPassword: md5(formData.matchPassword),
                                    userAgent
                                }
                                const Pixel = that.$store.getters.Pixel
                                if (Pixel) {
                                    params.trafficAcquisitionType = Pixel
                                }
                                const clickId = that.$store.getters.ClickId
                                if (clickId) {
                                    params.clickId = clickId
                                }
                                if (that.$store.getters.TaId) {
                                    params.taid = that.$store.getters.TaId
                                }
                                userRegister(params).then(res => {
                                    Toast(res?.message)
                                    if (!res?.code) {
                                        that.$store.dispatch('user/setInvitCode', '')
                                        that.form1.invitationCode = ''
                                        that.$store.dispatch('user/changeLoginType', 2)
                                        that.act = 2
                                        that.form2.phone = formData.phone
                                        that.form2.password = formData.password
                                        // login(formData).then(loginRes => {
                                        //     if (loginRes.code === 0) {
                                        //         that.$store.commit('user/SET_TOKEN', `${loginRes.result.tokenHead} ${loginRes.result.token}`)
                                        //         getUserInfo().then((uInfo) => {
                                        //             if (uInfo.code) return
                                        //             that.$store.dispatch('user/saveUserInfo', uInfo.result)
                                        //             that.$websocket.initWebSocket()
                                        //         })
                                        //         that.$store.dispatch('user/setIsShowLoginPopup', false)
                                        //         that.$emit('loginSucc')
                                        //     }
                                        // })
                                    }
                                }).finally(() => {
                                    // uni.hideLoading()
                                })
                            }, 300)
                        } else if (that.act === 2) {
                            const formdata = that.form2
                            const params = {
                                ...formdata,
                                password: md5(formdata.password)
                            }
                            login(params).then(res => {
                                Toast(res?.message)
                                if (res?.code === 0) {
                                    this.$store.dispatch('user/setInvitCode', '')
                                    if (this.rememberMe) {
                                        this.$store.dispatch('user/setRememberMe', formdata)
                                    } else {
                                        this.$store.dispatch('user/setRememberMe', null)
                                    }
                                    this.$store.commit('user/SET_TOKEN', `${res.result.tokenHead} ${res.result.token}`)
                                    getUserInfo().then((uInfo) => {
                                        if (uInfo.code) return
                                        that.$websocket.initWebSocket()
                                    })
                                    that.$store.dispatch('user/setIsShowLoginPopup', false)
                                    that.$emit('loginSucc')
                                }
                            }).finally(() => {
                                // uni.hideLoading()
                            })
                        }
                    } else {
                        console.log('error submit!!')
                        return false
                    }
                })
            }, 300)
        },
        getDownTimes() {
            const that = this
            let sysConfig = that.$store.getters.sysConfig
            if (!sysConfig) {
                const getGonfigInterval = setInterval(() => {
                    sysConfig = that.$store.getters.sysConfig
                    if (sysConfig) {
                        clearInterval(getGonfigInterval)
                        const currentTime = that.getLocalTime()
                        const launchTime = sysConfig.systemLaunchTime ? new Date(sysConfig.systemLaunchTime).getTime() : that.getLocalTime()
                        if (launchTime > currentTime) {
                            that.timestamp = launchTime - currentTime
                            that.isCanRegister = false
                        }
                    }
                }, 300)
            } else {
                const currentTime = that.getLocalTime()
                const launchTime = sysConfig.systemLaunchTime ? new Date(sysConfig.systemLaunchTime).getTime() : that.getLocalTime()
                if (launchTime > currentTime) {
                    that.timestamp = launchTime - currentTime
                    that.isCanRegister = false
                }
            }
        },
        onTimeDownChange(e) {
            this.timeData = e
        },
        onShowPhoneCodePopup() {
            this.isPhoneCode = false
            this.showPhoneCodePopup = true
        },
        phoneCodeChange(e) {
            const val = e.target.value
            if (val.length > 3) this.phoneCode = val.slice(0, 3)
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.phoneCode-content {
    background: $bg-popup-color;
    border-radius: .2rem;
    // height: 100px;
    width: 5.2rem;
    padding: 0 .28rem;
    padding-bottom: .3rem;

    .header {
        display: flex;
        height: 1rem;
        padding-left: .46rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: .1rem;

        .logo {
            height: .88rem;
            width: auto;
            will-change: auto !important;
        }

        .close {
            color: $font-iconfont-color;
            font-size: .36rem;
            font-weight: bold;
            height: 1rem;
            width: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }

    ::v-deep input::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }

    ::v-deep input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    ::v-deep input[type='number'] {
        -moz-appearance: textfield;
    }

    ::v-deep .ant-input {
        padding-left: .82rem !important;

        .phoneCode {
            width: 1rem !important;
            border: 0 !important;
        }
    }

    ::v-deep .ant-input {
        background-color: $bg-input-color !important;
        border-radius: 0.14rem;
        font-size: 0.22rem;
        height: 0.85rem;
        padding: 0px 0.65rem !important;
        color: #e3e3e3;
        border: .02rem solid $border-input-color !important;

        &:focus {
            background: $bg-input-act-color !important;
            border-color: $border-active-color !important;
        }

        &::-webkit-input-placeholder {
            /* WebKit browsers */
            font-size: .27rem;
            color: $font-placeholder-color;
            opacity: .9;
            font-weight: 700 !important;
        }

        &:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            font-size: .22rem;
            color: $font-placeholder-color;
        }

        &::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            font-size: .22rem;
            color: $font-placeholder-color;
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10+ */
            font-size: .22rem;
            color: $font-placeholder-color;
        }
    }
}

.content {
    background: $bg-popup-color;
    border-radius: .2rem;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 550px;

    &>.banner_pc {
        .mui-7ak6qf {
            place-content: center flex-start;
            -webkit-box-align: center;
            align-items: center;
            display: flex;
            flex-direction: column;
            -webkit-box-pack: start;
            justify-content: center;
            width: 400px;
            height: 465px;
            border-radius: 16px;
            margin-left: 16px;
            margin-right: 16px;
            margin-top: 30px;
            background: rgba(255,255,255,.1);
        }

        .mui-16z924v {
            place-content: center;
            -webkit-box-align: center;
            align-items: center;
            display: flex;
            flex-flow: column;
            -webkit-box-pack: center;

            .mui-16jo4oo {
                margin: 0px;
                font-size: 16px;
                font-weight: 400;
                color: rgb(129, 106, 166);
                text-transform: uppercase;
            }

            @media screen and (min-width: 1000px) {
                .mui-16jo4oo {
                    color: rgb(255, 255, 255);
                    font-size: 26px;
                    font-weight: 700;
                }
            }

            .mui-k008qs {
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
            }

            .mui-1u4w7oe {
                margin: 0px;
                font-size: 26px;
                font-weight: 700;
                font-family: __Rubik_7a808d, __Rubik_Fallback_7a808d;
                line-height: 1.235;
                font-style: normal;
                color: rgb(104, 242, 68);
            }

            @media screen and (min-width: 1000px) {
                .mui-1u4w7oe {
                    font-size: 48px;
                    font-weight: 900;
                    font-style: italic;
                }
            }

            .mui-1c295ux {
                margin: 0px 8px;
                font-size: 26px;
                font-weight: 700;
                font-family: __Rubik_7a808d, __Rubik_Fallback_7a808d;
                line-height: 1.235;
                font-style: normal;
                color: rgb(255, 255, 255);
            }

            @media screen and (min-width: 1000px) {
                .mui-1c295ux {
                    font-size: 48px;
                    font-weight: 900;
                    font-style: italic;
                }
            }

            .mui-1u4w7oe {
                margin: 0px;
                font-size: 26px;
                font-weight: 700;
                font-family: __Rubik_7a808d, __Rubik_Fallback_7a808d;
                line-height: 1.235;
                font-style: normal;
                color: rgb(104, 242, 68);
            }

            @media screen and (min-width: 1000px) {
                .mui-1u4w7oe {
                    font-size: 48px;
                    font-weight: 900;
                    font-style: italic;
                }
            }

            .mui-16jo4oo {
                margin: 0px;
                font-size: 16px;
                font-weight: 400;
                color: rgb(129, 106, 166);
                text-transform: uppercase;
            }

            @media screen and (min-width: 1000px) {
                .mui-16jo4oo {
                    color: rgb(255, 255, 255);
                    font-size: 26px;
                    font-weight: 700;
                }
            }
        }
    }

    &>.LogReg {
        width: 400px;
        height: 100%;

        .header {
            display: flex;
            height: 1rem;
            padding-left: .46rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: .1rem;

            .logo {
                height: .88rem;
                width: auto;
                will-change: auto !important;
            }

            .close {
                color: $font-iconfont-color;
                font-size: .36rem;
                font-weight: bold;
                height: 1rem;
                width: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
        }

        .LogReg-content {
            padding: 0 .42rem;
        }

        .tabs {
            display: flex;
            align-items: center;
            background: $bg-tab-color;
            border-radius: 30px;
            border: 2px solid $border-tab-color;
            width: min-content;
            margin-top: .15rem;

            .tab-item {
                color: $font-tab-color;
                box-sizing: border-box;
                font-weight: 500;
                font-size: .28rem;
                cursor: pointer;
                height: .6rem;
                padding: 0 .2rem;
                line-height: .59rem;
                border-radius: 30px;
                white-space: nowrap;

                &.act {
                    color: $font-btn-color;
                    background: $bg-btn-color;
                }
            }
        }
    }
}

.form-box {
    position: relative;

    .time_down_box {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
        background: rgba(15, 5, 53, .5);
        box-sizing: border-box;
        padding-top: 1px;

        .time_down {
            width: 300px;
            height: 120px;
            margin: 0 auto;
            box-sizing: border-box;
            background: rgba(0, 0, 0, .7);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-radius: 10px;
            color: #fff;
            margin-top: 56px;

            .time_down_title {
                font-size: 18px;
                margin-bottom: 12px;
            }

            .time_down_time {
                font-size: 15px;
                display: flex;
                align-items: center;

                .time {
                    color: $font-theme-color;
                    font-weight: bold;
                    font-size: 16px;
                }
            }
        }
    }

    .form {
        padding: .16rem .12rem;
        text-align: left;

        ::v-deep .ant-form {
            .ant-form-item {
                margin-bottom: .43rem;

                &.ant-form-item-with-help {
                    margin-bottom: 2.4px;
                }

                .language {
                    width: auto;
                    height: auto;
                    padding-right: .12rem;
                    // border-right: .01rem solid #838187;
                }
            }

            .has-error {
                .ant-input-prefix {
                    .iconfont {
                        position: relative;

                        &:after {
                            content: '*';
                            position: absolute;
                            color: #EA4E3D;
                            top: .02rem;
                            right: -.1.5rem;
                            font-size: .24rem;
                        }
                    }
                }
            }
        }

        .ant-input-affix-wrapper {
            background-color: transparent;

            &.phone {
                ::v-deep input::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                }

                ::v-deep input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                }

                ::v-deep input[type='number'] {
                    -moz-appearance: textfield;
                }

                ::v-deep .ant-input {
                    padding-left: 1.42rem !important;

                    .phoneCode {
                        width: 1rem !important;
                        border: 0 !important;
                    }
                }
            }

            &.account {
                ::v-deep .language {
                    border-right: .01rem solid #838187;
                }

                ::v-deep .ant-input {
                    // padding-left: .8rem !important;
                }
            }

            ::v-deep .ant-input-prefix {
                left: .14rem;
                color: $font-iconfont-color;

                .icon-person,
                .icon-mima {
                    font-size: .37rem;
                }

                .icon-mima {
                    font-size: .34rem;
                }

                .icon-open {
                    font-size: .2rem;
                }

                .icon-key-fill {
                    font-size: .34rem;
                }

                .icon-shebeizhuce {
                    font-size: .34rem;
                }

                .flag {
                    width: .28rem;
                    height: 0.22rem;
                    margin-right: .1rem;
                }
                .icon-shouji1{
                    font-size: .32rem;
                }
                span {
                    font-size: .23rem;
                    // margin-right: .1rem;
                    padding-right: .08rem;
                    color: #fff;
                    border-right: .01rem solid #838187;
                    display: inline-block;
                    width: .62rem;
                    cursor: pointer;
                    &.act{
                        border: .01rem solid #ff0000;
                        animation: blink 1s infinite;
                    }
                }
                @keyframes blink {
                    0% {
                        opacity: 1;
                    }
                    50% {
                        opacity: 0;
                    }
                    100% {
                        opacity: 1;
                    }
                }
            }

            ::v-deep .ant-input {
                background-color: $bg-input-color !important;
                border-radius: 0.14rem;
                font-size: 0.22rem;
                height: 0.85rem;
                padding: 0px 0.65rem !important;
                color: #e3e3e3;
                border: .02rem solid $border-input-color !important;

                &:focus {
                    background: $bg-input-act-color !important;
                    border-color: $border-active-color !important;
                }

                &::-webkit-input-placeholder {
                    /* WebKit browsers */
                    font-size: .27rem;
                    color: $font-placeholder-color;
                    opacity: .9;
                    font-weight: 700 !important;
                }

                &:-moz-placeholder {
                    /* Mozilla Firefox 4 to 18 */
                    font-size: .22rem;
                    color: $font-placeholder-color;
                }

                &::-moz-placeholder {
                    /* Mozilla Firefox 19+ */
                    font-size: .22rem;
                    color: $font-placeholder-color;
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer 10+ */
                    font-size: .22rem;
                    color: $font-placeholder-color;
                }
            }

            ::v-deep .ant-input-suffix {
                .ant-input-clear-icon {
                    color: $font-iconfont-color;
                    font-size: .32rem;
                }
            }

            &.ant-input-password {
                ::v-deep .ant-input {
                    padding-right: 1rem;
                }

                ::v-deep .ant-input-suffix {
                    .ant-input-password-icon {
                        margin-right: .1rem;
                        font-size: .3rem;
                        color: $font-iconfont-color;
                    }
                }
            }
        }

        .remember {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            .check-box {
                margin-bottom: .01rem;
                margin-right: .1rem;

                &>i {
                    font-size: .3rem;
                    color: $font-check-color;
                }

                &>i.checked {
                    color: $font-check-act-color;
                }
            }

            .txt {
                color: $font-theme-color;
                font-size: .21rem;
                cursor: pointer;
            }

            .tips {
                color: #fff;
                font-size: .22rem;
                line-height: .38rem;
                margin-top: 0.03rem;
            }
        }
    }
}

.btn {
    width: 100%;
    font-size: .24rem;
    height: .65rem;
    text-align: center;
    line-height: .65rem;
    background: $bg-btn-color;
    color: $font-btn-color;
    font-weight: bold;
    border-radius: .14rem;
    margin: .18rem 0;
    cursor: pointer;

    &.ant-btn {
        border-color: transparent;
    }
}
</style>
