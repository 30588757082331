export default {
    lang: {
        LoadMore: 'Cargar más',
        AllLoaded: 'Todo cargado',
        ChangeLanguage: 'Cambiar idioma',
        ChangePassword: 'Cambiar contraseña',
        copySuccess: '¡Éxito al copiar!',
        copyError: 'Error al copiar',
        CopyEmptyTips: 'El contenido copiado no puede estar vacío',
        empty: 'Vacío aquí',
        tips: 'Consejos',
        cancel: 'Cancelación',
        confirm: 'Confirmar',
        more: 'Mais',
        isCloseGame: '¿¿ se cierra el juego?',
        back: 'Atrás',
        Claim: 'Afirmar',
        Loading: 'Cargando',
        Play: 'Jugar',
        Download: 'Descargar'
    },
    tabbar: {
        Lobby: 'Principal',
        Earn: 'Compartir',
        Game: 'Juego',
        Event: 'Incidencias',
        Account: 'Cuenta',
        Recharge: 'Recargar',
        serve: 'Servicios',
        Withdraw: 'Retirada',
        Affiliate: 'Filial'
    },
    home: {
        Download: 'Descargar',
        DownloadTips: 'Descarga la aplicación y obtén ',
        Search: 'Buscar',
        Popular: 'Popular',
        Recent: 'Reciente',
        Favorite: 'Favorito',
        Providers: 'Proveedores',
        Categories: 'Categorías',
        CollectionSucc: 'Colección exitosa',
        CollectionCanceled: 'Colección cancelada',
        MORE: 'MÁS',
        all: 'Todos',
        Top: 'Top',
        greatBonus: 'Gran bono por depósito',
        pullRefresh: 'Desliza hacia abajo para actualizar...',
        releaseRefresh: 'Suelta para actualizar...',
        loading: 'cargando...',
        LiveSupport: 'Soporte en Vivo',
        help: 'Estamos aquí para ayudar',
        FastCashouts: 'Retiros Rápidos',
        FastCashoutsIn: 'Retiros rápidos en',
        less: '¡o menos!',
        minutes: '15 min'
    },
    login: {
        Login: 'Iniciar sesión',
        PhoneLogin: 'Inicio de sesión telefónico',
        MemberAccount: 'Cuenta de membresía',
        Password: 'Palabras clave',
        PleaseEnterAccount: 'Please enter your account number',
        PleaseEnterPassword: 'Por favor, introduzca la contraseña',
        RememberMe: 'Recuérdame',
        ForgetPassword: '¿Olvidó la contraseña?'
    },
    signin: {
        signInTitle: 'Bonificación diaria por inicio de sesión',
        signInDays: 'Iniciado sesión continuamente durante ',
        days: ' días',
        day: 'DÍA',
        claimed: 'Reclamado ',
        condition1: 'Depósito requerido',
        condition2: 'Apuestas requeridas',
        eventDetails: 'Detalles del evento',
        checkin: 'Registro',
        CheckIn: 'REGISTRARSE',
        Today: 'Hoy',
        Uncompleted: 'INCOMPLETO',
        Completed: 'COMPLETO',
        Conditions: 'Condiciones',
        Rules: 'Reglas'
    },
    share: {
        panel: 'Panel',
        FAQ: 'FAQ',
        TUTORIAL: 'TUTORIAL',
        TotalRewards: 'Recompensas Totales',
        TotalFriends: 'Total de Amigos',
        inviteFriend: 'INVITA AMIGOS PARA GANAR',
        Commission: 'Comisión',
        InviteVia: 'Invitar a través de:',
        copy: 'Copiar',
        link: 'Enlace',
        code: 'Código',
        or: 'O',
        Rewards: 'Recompensas',
        title1: 'COMISIÓN',
        tip1: 'Gana hasta',
        tip2: 'comisión cuando tu amigo apueste.',
        title2: 'CASHBACK DE DEPÓSITO',
        tip3: 'Gana',
        tip4: 'cuando tu amigo deposite.',
        Details: 'Detalles',
        UncollectedCommissions: 'Comisiones no cobradas',
        ExclusiveCommission: 'Comisión Exclusiva',
        DepositCashback: 'Cashback de depósito',
        Claim: 'Reclamar',
        TodayTeamData: 'Datos del equipo de hoy',
        TotalTeamData: 'Datos totales del equipo',
        SignUp: 'Registrarse',
        FirstDeposit: 'Primer Depósito',
        ValidBet: 'Apuesta Válida',
        LiveRewards: 'Recompensas en Vivo',
        SendTotalCommission: 'Enviar comisión total',
        rules: 'Normas',
        ruleInfo: '¡Puedes ganar dinero recomendando amigos y ganar hasta comisiones!',
        Recommended: 'Recomendada',
        Month: 'Mes',
        UnlimitedSubordinates: 'Desarrollo ilimitado de subordinados',
        planTitle: 'APRENDE MÁS SOBRE NUESTRO PROGRAMA DE AFILIADOS',
        planInfo: 'Si tienes una gran audiencia y seguidores. ¡Tenemos condiciones especiales para que personalices tu programa de referencia!',
        all: 'Todos',
        Direct: 'Directo',
        Indirect: 'Indirecto',
        TodayTeamIntro1: 'Registrarse: Número de usuarios que se registraron hoy',
        TodayTeamIntro2: 'Primer Depósito: Número de usuarios que hicieron su primer depósito hoy',
        TodayTeamIntro3: 'Apuestas Válidas: Apuestas válidas para el equipo de hoy',
        TotalTeamIntro1: 'Registrarse: Total de inscripciones del equipo',
        TotalTeamIntro2: 'Primer Depósito: Total de depositantes por primera vez',
        TotalTeamIntro3: 'Apuestas Válidas: El total de apuestas efectivas del equipo'
    },
    Vip: {
        VIPSupport: 'Soporte VIP',
        LevelBenefits: 'Nivel VIP y Beneficios',
        Deposits: 'Depósitos',
        Bets: 'Apuestas',
        or: 'o',
        to: 'a',
        joinClub: 'Únete al Club VIP. Desbloquea altos reembolsos. Todo tipo de regalos exquisitos. ¡Te sorprenderá!',
        levelUp: 'Sube de nivel a VIP',
        Steps: 'Pasos',
        Benefits: 'Beneficios',
        benefits1: 'Bono por subir de nivel',
        benefits2: 'Bono semanal',
        benefits3: 'Bono mensual',
        info1: '¡Sube de nivel VIP para obtener un bono!',
        info2: 'Reclama un bono gratis cada semana.',
        info3: 'Reclama un bono gratis cada mes.',
        More: 'Más',
        promotionTip: 'Por favor, consulta nuestras promociones para más detalles.',
        Promotions: 'Promociones',
        FAQ: 'Preguntas Frecuentes',
        question1: '1. ¿Qué es el "Bono por subir de nivel"?',
        answer1: ' Un "bono por subir de nivel" es un beneficio VIP donde las recompensas aumentan a medida que avanzas a niveles VIP más altos.',
        question2: '2. Beneficios de mejorar a VIP',
        answer2: '¡Mejorar tu VIP puede obtener una mayor cantidad de retiro y una menor tarifa de retiro!',
        LevelUpBonus: 'Bono por subir de nivel',
        WeeklyBonus: 'Bono semanal',
        MonthlyBonus: 'Bono mensual',
        Level: 'Nivel',
        RequiredDeposit: 'Depósito requerido',
        RequiredBet: 'Apuesta requerida',
        Get: 'Obtener',
        UpgradeBonus: 'Bono de mejora',
        vipRewards: '¡Regístrate y obtén recompensas VIP exclusivas!',
        earnPoints: '¡Apuesta y gana puntos!',
        levelUpBenifits: '¡Desbloquea recompensas, mejora tus beneficios!',
        becomeVip: '¿Cómo convertirse en VIP?',
        vipRequire: '¡Regístrate, haz apuestas y comienza a jugar! Por cada $1 que apuestes, obtendrás 1 punto. Cuantos más puntos obtengas, mayor será tu nivel y más privilegios tendrás.',
        improveLevel: '¿Cómo mejorar de nivel?',
        Step1: 'Regístrate en el sitio',
        Step2: 'Haz un depósito',
        Step3: 'Gira tus tragamonedas favoritas',
        Step4: '¡Sube de nivel!',
        Levels: 'Niveles',
        advantage1: 'Soporte en vivo 24/7',
        advantage2: 'Bono por alcanzar el nivel'
    }
}
