<template>
    <div class="Ranking">
        <div class="dateBox">
            <div class="date" :id="'id' + index" v-for="(item, index) in dateRange" :key="item.id"
                :class="{ 'act': dateAct == item.id }" @click="changeTime(item.id)">
                {{ item.time }}
            </div>
        </div>
        <div class="history" @click="$refs.HistoryPopup.open()">{{ $t("winRankList.History") }}</div>
        <div class="jackpot">
            <div class="jackpot_num">
                <div class="jackpot-count">
                    <count-to ref="jackpotCountTo" color="#FFFFFF" fontSize=".44rem" bold duration="5000" :decimals="2"
                        :autoplay="false" :endVal="prizePoolAmt" separator=","></count-to>
                </div>
            </div>
        </div>
        <div class="Ranking_list">
            <van-list class="scroll-list">
                <div v-if="list.length" class="cards">
                    <div v-for="(item, index) in list" :key="index" class="cards_items"
                        :class="{ 'rank': index <= 2, 'rank_one': index == 0, 'rank_two': index == 1, 'rank_three': index == 2 }">
                        <div class="info">
                            <div class="left">
                                <div class="sort">{{ item.rankNumber }}</div>
                                <div class="img">
                                    <img mode="aspectFit"
                                        :src="userInfo.avatar || require('@/assets/person/avatar.avif')" />
                                    <div class="avatar_border"></div>
                                </div>

                                <div class="baseInfo">
                                    <div class="phone">{{ item.showName }}</div>
                                    <div>{{ $t('winRankList.Reward') }}{{ prizePoolRewardsType == 'SCALE' ?
                                        (item.rewardsPercent + '%') : (CurrencyType + ' ' + item.rewardsAmt) }}</div>
                                </div>
                            </div>
                            <div class="baseInfo">
                                <div class="right_title">{{ $t('winRankList.TotalBets') }}</div>
                                <div class="right_value">{{ CurrencyType }} {{ item.grandTotalBetting }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="no_data">
                    <div v-if="loading" class="loading">
                        <van-loading type="spinner" />
                        <span>{{ $t('lang.Loading') }}</span>
                    </div>
                    <div v-else class="empty">
                        <img src="@/assets/common/empty.png" />
                        <span>{{ $t('lang.empty') }}</span>
                    </div>
                </div>
                <div style="height: .1rem;"></div>
            </van-list>
        </div>
        <div class="pageFooter">
            <div class="sort">{{ memberRankNumber ? memberRankNumber : $t('winRankList.NoRank') }}</div>
            <div class="info">
                <div class="left">
                    <div>
                        <span class="label">{{ $t('winRankList.MyBets') }}</span>
                        <span class="val">{{ memberGameBetting }}</span>
                    </div>
                    <div><span class="label">{{ $t('winRankList.MyReward') }}</span> {{ prizePoolRewardsType == 'SCALE'
                        ? (memberRewardsPercent + '%') : (CurrencyType + ' ' + memberRewardsAmt) }}</div>
                </div>
                <div class="right">
                    <div>{{ $t('winRankList.RanksLeft') }}</div>
                    <div class="val">{{ CurrencyType }} {{ memberLeftAddGameBetting }}</div>
                </div>
            </div>
        </div>
        <HistoryPopup ref="HistoryPopup" :eventId="eventId"></HistoryPopup>
    </div>
</template>

<script>
import { getNowRankingList } from '@/api/winRankList.js'
import countTo from '@/components/countTo'
import HistoryPopup from './HistoryPopup.vue'
export default {
    components: { countTo, HistoryPopup },
    props: {
        rankingListTypeList: {
            type: Array,
            default: () => []
        },
        eventId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            dateAct: 0,
            dateRange: [],
            loading: false,
            list: [],
            memberGameBetting: '', // 我的投注
            memberLeftAddGameBetting: '', // 距离上一名还需要投注多少
            memberRankNumber: '', // 我的排名
            memberRewardsPercent: '', // 我的奖励百分比
            memberRewardsAmt: '',
            prizePoolAmt: '', // 奖池金额
            prizePoolRewardsType: 'SCALE'
        }
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo
        }
    },
    watch: {
        rankingListTypeList(newVal, oldVal) {
            if (newVal && newVal.length) {
                const list = []
                newVal.forEach(x => {
                    if (x === 'everyday') {
                        const obj = {
                            id: list.length,
                            time: this.$t('winRankList.Daily'),
                            key: x
                        }
                        list.push(obj)
                    }
                    if (x === 'weekly') {
                        const obj = {
                            id: list.length,
                            time: this.$t('winRankList.Weekly'),
                            key: x
                        }
                        list.push(obj)
                    }
                    if (x === 'monthly') {
                        const obj = {
                            id: list.length,
                            time: this.$t('winRankList.Monthly'),
                            key: x
                        }
                        list.push(obj)
                    }
                })
                this.dateRange = list
                this.dateAct = 0
                this.getData()
            }
        }
    },
    methods: {
        getData() {
            const params = {
                eventId: this.eventId,
                rankingListType: this.dateRange[this.dateAct].key
            }
            getNowRankingList(params).then((res) => {
                if (res.code) return
                const D = res.result
                this.list = D.memberRankingListInfoMessageDetailVoList
                this.memberGameBetting = D.memberGameBetting
                this.memberLeftAddGameBetting = D.memberLeftAddGameBetting
                this.memberRankNumber = D.memberRankNumber
                this.memberRewardsPercent = D.memberRewardsPercent
                this.memberRewardsAmt = D.memberRewardsAmt
                this.prizePoolAmt = D.prizePoolAmt
                this.prizePoolRewardsType = D.prizePoolRewardsType
                this.$nextTick(() => {
                    this.$refs.jackpotCountTo.start()
                })
            })
        },
        lower() { },
        changeTime(idx) {
            this.dateAct = idx
            this.getData()
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.Ranking {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .history {
        position: absolute;
        left: 0;
        top: .8rem;
        background: linear-gradient(0deg, #838182, #FFFEF9, #FFFFFF);
        border: 1px solid #2C0507;
        border-radius: 0 .2rem .2rem 0;
        padding: .07rem .3rem;
        font-size: .24rem;
        color: #292B32;
        z-index: 3;
        cursor: pointer;
    }

    .jackpot {
        margin: .2rem .5rem;
        cursor: pointer;
        height: 2rem;
        background-position: 0px 0px;
        background-image: url('@/assets/home/bg_jackpot.png');
        background-size: 100% 100%;
        position: relative;

        .jackpot-count {
            position: absolute;
            // border: 1px solid red;
            top: 50%;
            left: 0;
            right: 0;
            bottom: 40%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

}

.dateBox {
    padding: .07rem 0 .07rem .2rem;
    white-space: nowrap;
    // background: #666D7C;
    border-bottom: .01rem solid $border-color;
    text-align: left;

    .date {
        margin-right: .1rem;
        display: inline-block;
        padding: 0 .16rem;
        font-size: .20rem;
        height: .5rem;
        line-height: .5rem;
        color: $font-sub-color;
        background: linear-gradient(163deg, #16191E, #3B404C);
        // border: .01rem solid #5F5F65;
        border-radius: .06rem;
        white-space: nowrap;
        min-width: 1rem;
        text-align: center;
        box-sizing: border-box;
        cursor: pointer;

        &:last-child {
            margin-right: .5rem;
        }

        &.act {
            color: $font-btn-color;
            // border: .01rem solid #FFC627;
            background: $bg-btn-color;
        }
    }
}

.Ranking_list {
    flex: 1;
    width: 100%;
    overflow: hidden;
    padding-bottom: .01rem;
    position: relative;
    margin-top: 0;
    z-index: 2;

    .scroll-list {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-x: hidden;
        overflow-y: auto;
        box-sizing: border-box;
    }

    .cards {
        margin: .2rem .28rem;
        padding: 0 .24rem;
        padding-bottom: .2rem;
        border: .01rem solid $border-color;
        border-radius: .2rem;
        // background: #12121E;
        margin-top: 4rem;

        .cards_items {
            padding: .2rem;

            &:nth-child(2n+1) {
                background: $bg-menu-color;
                border-radius: .12rem;
            }

            .time {
                width: 100%;
                text-align: right;
                margin-bottom: .15rem;
                font-size: .2rem;
                color: $font-sub-color;
            }

            .info {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .left {
                    display: flex;
                    align-items: center;

                    .sort {
                        color: #FFFFFF;
                        font-size: .52rem;
                    }
                }

                .img {
                    &>img {
                        width: .92rem;
                        height: .92rem;
                        border-radius: 50%;
                        border: .01rem solid $border-color;
                        margin: 0 .24rem;
                    }
                }

                .baseInfo {
                    &>div:first-child {
                        color: $font-sub-color;
                        font-size: .24rem;
                    }

                    &>div:last-child {
                        color: #F7D515;
                        font-size: .24rem;
                        margin-top: .12rem;
                        white-space: nowrap;
                    }
                }

                &>div:last-child {
                    text-align: right;
                }
            }

            &.rank {
                position: absolute;
                width: 33%;
                padding: 0;
                flex-direction: column;
                margin: 0;

                &:nth-child(2n+1) {
                    background: transparent;
                }

                .time {
                    display: none;
                }

                .left {
                    flex-direction: column;
                    width: 100%;

                    .baseInfo {
                        width: 100%;
                        padding: 0 .18rem;
                        white-space: nowrap;
                    }
                }

                .sort {
                    color: transparent;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    position: relative;
                    z-index: 3;
                }

                .right_title {
                    display: none;
                }

                .right_value {
                    margin-top: 0 !important;
                }

                .phone {
                    background: $bg-menu-color;
                    border-radius: .5rem;
                    width: 100%;
                }

                .img {
                    position: absolute;

                    &>img {
                        width: 1.3rem;
                        height: 1.3rem;
                        vertical-align: middle;
                        border-radius: .12rem;
                        margin: 0;
                        margin-top: -.14rem;
                        position: relative;
                        z-index: 2;
                        background-color: #050518;
                        border-radius: 50%;
                        border: none;
                    }

                    .avatar_border {
                        position: absolute;
                        top: -30%;
                        left: -15%;
                        right: -15%;
                        bottom: -10%;
                        background-position: top left;
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        z-index: 9;
                    }
                }

                .info {
                    flex-direction: column;
                    width: 100%;
                    margin-top: .2rem;
                    padding-top: .48rem;
                    padding-bottom: 16upx;
                    background: linear-gradient(0deg, #373a3b, #222425);
                    box-shadow: 0 .03rem .06rem 0 rgba(0, 0, 0, 0.18);
                    border-radius: .2rem;
                    text-align: center;
                    justify-content: flex-start;
                    border-radius: .5rem .5rem 0 0 !important;

                    .title {
                        font-size: .24rem;
                    }

                    .count {
                        font-weight: normal;
                        color: rgba(0, 0, 0, 0.65);
                        font-size: .18rem;
                        margin-top: .08rem;
                    }
                }
            }

            &.rank_one {
                left: 35%;
                top: .38rem;
                padding-right: .2rem;

                .img {
                    top: 0%;

                    .avatar_border {
                        top: -33%;
                        background-image: url(@/assets/winRankList/icon_sort1.png);
                    }
                }

                .info {
                    height: 3.42rem;
                    padding-top: 1.1rem;
                }
            }

            &.rank_two {
                left: .1rem;
                top: .9rem;
                padding-left: .2rem;
                padding-right: .14rem;

                .img {
                    top: -12%;

                    .avatar_border {
                        top: -38%;
                        background-image: url(@/assets/winRankList/icon_sort2.png);
                    }
                }

                .info {
                    height: 2.9rem;
                    position: relative;
                    padding-top: .92rem;
                }
            }

            &.rank_three {
                right: .1rem;
                top: 1.1rem;
                padding-left: .16rem;
                padding-right: .2rem;

                .img {
                    top: -8%;

                    .avatar_border {
                        top: -35%;
                        background-image: url(@/assets/winRankList/icon_sort3.png);
                    }
                }

                .info {
                    height: 2.7rem;
                    padding-top: .8rem;

                    .count {
                        display: none;
                    }
                }
            }
        }

    }

    .last-text {
        color: #7A787F;
        display: block;
        font-size: .22rem;
        margin-top: .02rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: .8rem;

        &>span {
            margin-right: .08rem;
        }

        &>i {
            font-size: .24rem;
            vertical-align: middle;
        }
    }

    .no_data {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: .24rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        .loading {
            &>span:last-child {
                display: inline-block;
                margin-top: .2rem;
            }
        }

        .empty {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &>img {
                width: 2.4rem;
                height: auto;
            }
        }

        span {
            line-height: .8rem;
        }
    }
}

.pageFooter {
    display: flex;
    align-items: center;
    background: $bg-menu-color;
    width: 100%;
    height: 1.4rem;

    .sort {
        width: 1.6rem;
        text-align: center;
        color: #fff;
        line-height: 1.4rem;
        font-size: .24rem;
        line-height: .28rem;
    }

    .info {
        width: calc(100% - 1.6rem);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: .2rem .28rem;
        padding-left: .2rem;
        color: #F4BA7B;
        line-height: .26rem;
        font-size: .26rem;

        .left {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            height: .98rem;
        }

        .right {
            text-align: right;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: flex-end;
            height: .98rem;
        }

        .label {
            margin-right: .18rem;
        }

        .val {
            color: #FDAE00;
            font-weight: bold;
        }
    }
}
</style>
